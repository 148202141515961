import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { ethers } from "ethers";
import { useContext, useMemo, useState } from "react";
import { useAccount, useSigner } from "wagmi";
import Loading from "../../connectivityAssets/Loading";
import { marketplaceContractAddr } from "../../connectivityAssets/mintContract/addresses";
import useNfts from "../../hooks/useNfts";
import { LISTING, NFT_STATUS, ORDER_STATUS } from "../../utils/constant";
import { approveNFT, cancelItemForSale, getApprovedNFT, getNewOrderId, putItemForSale, updateItemForSale } from "../../utils/contract";
import { get, post, update } from "../../utils/fetchApis";
import { getComparator, stableSort } from "../../utils/helper";
import ListStatModal from "../Modal/ListStatModal";
import NFTTableRow from "../NFTTableRow";
import { config } from "../../utils/wagiConfig";
import { getEthersSigner } from "../../utils/ether";
import { isAxiosError } from "axios";
import { AppContext } from "../../Context";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    label: "ID",
  },
  {
    id: "tokenId",
    numeric: false,
    disablePadding: false,
    label: "NFT",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price (USDT)",
  },
  {
    id: "marketplaceSaleId",
    numeric: false,
    disablePadding: false,
    label: "No of sales",
  },
  {
    id: "royalty",
    numeric: false,
    disablePadding: false,
    label: "Royalities (USDT)",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

const TransactionsContent = () => {    
  const [eNft, seteNft] = useState(null);
  const {nfts, loading, execute} = useNfts();
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const [status, setStatus] = useState(LISTING.START);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("nft_token_id");
  const { wallet, setAlertState } = useContext(AppContext)  

  const toggelModal = () => {
    setPriceModalOpen(!priceModalOpen);
  };

  const completeList = async (price) => {
    try {
      const signer = await getEthersSigner(config)
      setStatus(LISTING.APPROVING);      

      const approved = await getApprovedNFT(eNft.tokenId)
      if (approved !== marketplaceContractAddr) {
        await approveNFT(signer, eNft.tokenId);
      }

      await putItemForSale(signer, eNft.tokenId, ethers.utils.parseUnits(price, 6));
      const orderId = await getNewOrderId();

      const params = {
        marketplaceSaleId: parseInt(orderId) - 1,
        marketplaceAddress: marketplaceContractAddr,
        tokenId: eNft.tokenId,
        price,
        status: ORDER_STATUS.SALE,
        walletId: wallet.id,
      };
      
      await post(`orders/${eNft.id}/create`, params);
      await execute();

      setStatus(LISTING.LIST_FINISH);
    } catch (e) {
      let message = 'something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const updateList = async (price) => {
    try {      
      setStatus(LISTING.APPROVING);

      const signer = await getEthersSigner(config)
      await updateItemForSale(signer, eNft.order.marketplaceSaleId, ethers.utils.parseUnits(price, 6));

      const params = {        
        price,
      };
      
      await update(`orders/${eNft.order.id}`, params);
      await execute();

      setStatus(LISTING.EDIT_FINISH);
    } catch (e) {

      let message = 'Something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const cancelList = async (nft) => {
    try {

      setStatus(LISTING.CANCELING);
      
      const signer = await getEthersSigner(config)
      await cancelItemForSale(signer, eNft.order.marketplaceSaleId);

      const params = {        
        status: ORDER_STATUS.CANCEL,
      };
      await update(`orders/${eNft.order.id}`, params);
      await execute();

      setPriceModalOpen(false);
    } catch (e) {

      let message = 'Something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const handleEdit = (nft) => {    
    seteNft(nft);
    setStatus(nft.status === NFT_STATUS.SALE ? LISTING.EDIT : LISTING.START);
    setPriceModalOpen(true);
  };

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);

    setAlertState({
      open: true,
      message: "Copied!",
      severity: "info",
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    return stableSort(nfts, getComparator(orderBy, order)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [order, orderBy, page, rowsPerPage, nfts]);

  // console.log("nfts", visibleRows);
  // console.log("loading", loading);

  return (
    <Box>
      {priceModalOpen && (
        <ListStatModal
          status={status}
          nft={eNft}
          priceModalOpen={priceModalOpen}
          completeList={(price) => completeList(price)}
          updateList={(price) => updateList(price)}
          cancelList={cancelList}
          toggelModal={toggelModal}
        />
      )}
      
      <Typography variant="h2" fontFamily="'Montserrat', sans-serif">
        Owned NFT&#39;s
      </Typography>

      {loading && <Loading isLoading={true} />}
      <TableContainer component={Paper} sx={{ mt: "10px" }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={nfts.length}></EnhancedTableHead>

            <TableBody>
              {visibleRows.map((nft, idx) => (
                <NFTTableRow key={idx} nft={nft} list={handleEdit} copy={handleCopy}></NFTTableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={nfts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              ></TablePagination>
            </TableFooter>
          </Table>

          {nfts.length < 1 && (
            <Box py="40px" textAlign="center">
              <Typography variant="h3" textAlign="center">
                No NFT Yet
              </Typography>
            </Box>
          )}
        </TableContainer>
    </Box>
  );
};

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: "rgb(111, 218, 68)",
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ whiteSpace: "nowrap", color: "white" }}
          >
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : "asc"} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TransactionsContent;
