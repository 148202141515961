import React, { useEffect } from "react";
import { BubbleSpinLoader } from "react-css-loaders2";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Container, Grid, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";

const CheckOutStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const status = location?.state?.status;

  useEffect(() => {
    if (status) {
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      navigate("/");
    }
  }, [navigate, status]);
  return (
    <div>
      <Container maxWidth="lg">
        {
          // status loading
          status === "loading" && (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <BubbleSpinLoader color="#6FDA44" />
              <Typography variant="h2">Please wait</Typography>
              <Typography> We are processing your order </Typography>
            </Grid>
          )
        }

        {
          // status comfirm
          status === "confirm" && (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CheckCircleIcon
                sx={{ color: "#6FDA44", width: 150, height: 150 }}
              />
              <Typography variant="h2" my={2}>
                Order completed succesfuly!
              </Typography>
              <Typography>
                Thank you for joining Sniser! You will be redirected in
              </Typography>
              <Typography fontWeight={500}>5 seconds</Typography>
            </Grid>
          )
        }

        {
          // status cancel
          status === "cancel" && (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CancelIcon sx={{ color: "#E12B2B", width: 150, height: 150 }} />

              <Typography variant="h2" my={2}>
                Order failed!
              </Typography>
              <Typography>
                Something did not work. Please try again later or
              </Typography>
              <Typography>contact us for more information!</Typography>
            </Grid>
          )
        }
      </Container>
    </div>
  );
};

export default CheckOutStatus;
