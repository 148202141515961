import { useState, useEffect, useCallback } from 'react'
import { getNFTOwner } from '../utils/contract';

function useNftOwner(nft_id: string) {
    const [owner, setOwner] = useState(null)

    const getOwner = useCallback(async () => {        
        try {     
            if (nft_id) {                
                const data = await getNFTOwner(nft_id)    
      
                setOwner(data)
            }
    
        } catch (error) {
          console.log(error);
        }
      }, [nft_id]);

    useEffect(() => {        
        getOwner()
    }, [nft_id])
    
    return {owner, getOwner}
}

export default useNftOwner
