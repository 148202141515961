import React from "react";
import { TextField } from "@mui/material";

const inputStyle = {
  Input: {
    color: "#000",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontWeight: 500,
      fontSize: "14px",
      color: "#000",
    },
  },
  width: "100%",
  backgroundColor: "#edecec",
  borderRadius: "5px",

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#edecec",
    },
    "&:hover fieldset": {
      borderColor: "#edecec",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#edecec",
    },
  },
};

const CustomInput = (props) => {
  return <TextField {...props} sx={inputStyle} size="small" />;
};

export default CustomInput;
