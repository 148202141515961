import { useState, useEffect, useCallback } from "react";
import { post } from "../utils/fetchApis";
import axios from "axios";

function useFetchFaq() {
  const [faqData, setFaqData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getFaqData = useCallback(async () => {
    try {
      setIsLoading(true);
      
      const {data} = await axios.post('https://sniser.com/API/faq-list.php')

      if (data?.status === "200") {
        setFaqData(data?.faqList);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getFaqData();
  }, [getFaqData]);

  return {faqData, isLoading};
}

export default useFetchFaq;
