import React, { useContext, useState } from "react";

import ContentCopy from "@mui/icons-material/ContentCopy";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SvgIcon,
  TableCell,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import { useAccount } from "wagmi";
import { GC_BUCKET_URL } from "../utils/constant";
import { NFT_STATUS } from "../utils/constant";
import { AppContext } from "../Context";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6FDA44",
    color: theme.palette.common.white,
    // width: '30%'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: "'Montserrat', sans-serif",
  },
  fontFamily: "'Montserrat', sans-serif",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const NFTTableRow = ({ nft, list, copy }) => {
  const { address } = useAccount();
  const [anchorEl, setAnchorEl] = useState(null);
  const {wallet} = useContext(AppContext)  

  const open = Boolean(anchorEl);

  const handleClick = React.useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    setAnchorEl(null);
    list(nft);
  };

  const handleCopy = () => {
    setAnchorEl(null);
    const url = `${window.location.origin}/nft/${nft.id}`;
    copy(url);
  };

  const order = nft.orders && nft.orders.length ? nft.orders[0] : null;
  // console.log('owner', nft)

  return (
    <StyledTableRow>
      <StyledTableCell align="left" size="small">
        {nft.id}
      </StyledTableCell>
      <StyledTableCell component="th" scope="row" align="left" size="small">
        <Box display="flex" alignItems={"center"} py="10px">
          <img src={GC_BUCKET_URL + nft?.image} alt="nft" style={{ width: "70px", height: "45px" }} />
          <Box>
            <Typography pl="10px" variant="overline" fontSize="12px">
              {nft?.title}
            </Typography>
            <div style={{ marginLeft: "10px" }}># {nft?.tokenId} </div>
          </Box>
        </Box>
      </StyledTableCell>
      <StyledTableCell align="left" size="small">
        {nft.price ?? "Not listed"}
      </StyledTableCell>
      <StyledTableCell align="left" size="small">
        {nft.orderId}
      </StyledTableCell>
      <StyledTableCell align="left" size="small">
        {nft.royalty}
      </StyledTableCell>
      <StyledTableCell align="right" size="small">
        <Button onClick={handleClick}>
          <SvgIcon>
            {/* { THREE_DOT_VERTICAL } */}
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0" />
            </svg>
          </SvgIcon>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleEdit}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              {nft.status === NFT_STATUS.SALE ? 'Edit list' : 'List for sale'}
            </ListItemText>
          </MenuItem>
          <Divider component="li" />
          <MenuItem onClick={handleCopy}>
            <ListItemIcon>
              <ContentCopy fontSize="small" />
            </ListItemIcon>
            <ListItemText>Copy link</ListItemText>
          </MenuItem>
        </Menu>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default NFTTableRow;
