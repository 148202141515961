import React, { useEffect, useState } from "react";

import { Box, Button, Checkbox, Stack, TextField, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import Ticon from "../assets/ticon-circle.png";
import { FILTER_FIELD, ORDER_STATUS } from "../utils/constant";

const DashboardSidebarWeb = ({
  sidebar,
  setFilterdNfts,
  // filterdNfts,
  allNfts,
}) => {
  const [newFitler, setNewFilter] = useState(false);
  const [resaleFilter, setResaleFilter] = useState(false);
  const [videoFilter, setVideoFilter] = useState(false);
  const [audioFilter, setAudioFilter] = useState(false);
  const [minPrice, setMinPrice] = useState(undefined);
  const [maxPrice, setMaxPrice] = useState(undefined);
  const [isFilterPrice, setIsFilterPrice] = useState(false);

  const clearFilter = () => {
    setMinPrice(undefined);
    setMaxPrice(undefined);
    setNewFilter(false);
    setResaleFilter(false);
    setAudioFilter(false);
    setVideoFilter(false);
  };

  const handleFilterChange = (field) => (e) => {
    if (field === FILTER_FIELD.NEW) {
      setNewFilter(e.target.checked);
    }
    if (field === FILTER_FIELD.RESALE) {
      setResaleFilter(e.target.checked);
    }
    if (field === FILTER_FIELD.AUDIO) {
      setAudioFilter(e.target.checked);
    }
    if (field === FILTER_FIELD.VIDEO) {
      setVideoFilter(e.target.checked);
    }
    if (field === FILTER_FIELD.PRICE_MIN) {
      setMinPrice(e.target.value);
    }
    if (field === FILTER_FIELD.PRICE_MAX) {
      setMaxPrice(e.target.value);
    }
  };

  useEffect(() => {
    let nfts = allNfts;

    if (newFitler && !resaleFilter) {
      nfts = nfts.filter((nft) => {
        if (!nft.orders.length) return true;        
        return nft.orders.length === 1;
      });
    }
    if (resaleFilter && !newFitler) {
      // console.log("filter resale");
      nfts = nfts.filter((nft) => {
        const saleOrders = nft.orders.filter((order) => order.status === ORDER_STATUS.SOLD);
        return saleOrders.length > 0;
      });
    }
    if (audioFilter && !videoFilter) {
      // console.log("filter auduo");
      nfts = nfts.filter((nft) => {
        return nft.fileType.toLowerCase() === "audio";
      });
    }
    if (videoFilter && !audioFilter) {
      // console.log("filter video");
      nfts = nfts.filter((nft) => {
        return nft.fileType.toLowerCase() === "video";
      });
    }
    if (isFilterPrice) {
      if (minPrice) {
        nfts = nfts.filter((nft) => {          
          return Number(nft.price) >= Number(minPrice);
        });
      }
      if (maxPrice) {
        nfts = nfts.filter((nft) => {          
          return Number(nft.price) <= Number(maxPrice);
        });
      }
    }

    setFilterdNfts(nfts);
  }, [allNfts, newFitler, resaleFilter, videoFilter, audioFilter, minPrice, maxPrice, setFilterdNfts, isFilterPrice]);

  return (
    <Box
      display={!sidebar ? "none" : "block"}
      sx={{
        transition: "0.5s ease-out",
        backgroundColor: "#2A3538",
        borderRadius: "12px",
        padding: "20px",
      }}
    >
      <Stack direction={"row"} justifyContent="space-between" alignItems={"center"}>
        <Typography color={"#6FDA44"} variant="h4" sx={{ fontWeight: 700, fontSize: "20px" }}>
          Filters
        </Typography>
      </Stack>
      <Typography
        mt="20px"
        variant="h4"
        sx={{
          fontWeight: 700,
          color: "#fff",
          borderBottom: "1px solid #ffffff",
          pb: "8px",
        }}
      >
        Price - USDT
      </Typography>
      <Stack direction={"row"} justifyContent="space-between" alignItems={"center"} gap="5px" mt={"15px"} mb={"4px"} pb={2}>
        <Checkbox onChange={(e) => setIsFilterPrice(e.target.checked)} value="resale" checked={isFilterPrice} sx={{ color: "#fff", paddingX: 0 }} />
        <Box sx={{ position: "relative" }}>
          <TextField
            onChange={handleFilterChange(FILTER_FIELD.PRICE_MIN)}
            value={minPrice}
            type="number"
            sx={{
              width: "100%",
              Input: {
                color: "#ffffff",
                border: "1px solid #fff",
                borderRadius: "4px",
                fontWeight: 400,
                fontSize: "12px",
                paddingLeft: "28px",
                paddingRight: 0,
                "&::placeholder": {
                  textOverflow: "ellipsis !important",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "21px",
                },
              },
              backgroundColor: "#2A3538",
            }}
            placeholder="Min"
            size="small"
          />
          <Box sx={{ position: "absolute", top: "25%", left: "10px" }}>
            <img src={Ticon} style={{ width: "16px", height: "16px" }} alt="" />
          </Box>
        </Box>
        <RemoveIcon sx={{ color: "#fff" }} />
        <Box sx={{ position: "relative" }}>
          <TextField
            onChange={handleFilterChange(FILTER_FIELD.PRICE_MAX)}
            type="number"
            value={maxPrice}
            sx={{
              width: "100%",
              Input: {
                color: "#ffffff",
                border: "1px solid #fff",
                borderRadius: "4px",
                fontWeight: 400,
                fontSize: "12px",
                paddingLeft: "28px",
                paddingRight: 0,
                "&::placeholder": {
                  textOverflow: "ellipsis !important",
                  fontWeight: 400,
                  fontSize: "12px",
                  lineHeight: "21px",
                },
              },
              backgroundColor: "#2A3538",
            }}
            placeholder="Max"
            size="small"
          />
          <Box sx={{ position: "absolute", top: "25%", left: "10px" }}>
            <img src={Ticon} style={{ width: "16px", height: "16px" }} alt="" />
          </Box>
        </Box>
      </Stack>

      <Typography mb="10px" variant="h4" fontWeight={700} color={"#fff"} sx={{ borderBottom: "1px solid #ffffff", pb: "6px" }}>
        Type
      </Typography>
      <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
        <Checkbox onChange={handleFilterChange(FILTER_FIELD.NEW)} value="new" checked={newFitler} sx={{ color: "#fff", paddingX: 0 }} />
        <Typography sx={{ fontWeight: 700, fontSize: "14px", color: "#fff" }}>New</Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
        <Checkbox onChange={handleFilterChange(FILTER_FIELD.RESALE)} value="resale" checked={resaleFilter} sx={{ color: "#fff", paddingX: 0 }} />
        <Typography sx={{ fontWeight: 700, fontSize: "14px", color: "#fff" }}>Resale</Typography>
      </Stack>
      <Stack direction={"row"} alignItems={"center"} gap={"10px"}>
        <Checkbox onChange={handleFilterChange(FILTER_FIELD.VIDEO)} value="video" checked={videoFilter} sx={{ color: "#fff", paddingX: 0 }} />
        <Typography sx={{ fontWeight: 700, fontSize: "14px", color: "#fff" }}>Video</Typography>
      </Stack>
      <Stack direction={"row"} gap={"10px"} alignItems={"center"}>
        <Checkbox onChange={handleFilterChange(FILTER_FIELD.AUDIO)} value="audio" checked={audioFilter} sx={{ color: "#fff", paddingX: 0 }} />
        <Typography sx={{ fontWeight: 700, fontSize: "14px", color: "#fff" }}>Audio</Typography>
      </Stack>

      <Box display={"flex"} mt={"20px"} justifyContent={"space-between"} columnGap={"10px"}>
        <Button
          sx={{
            py: "10px",
            fontWeight: 700,
            fontFamily: "Montserrat",
            color: "#2A3538",
            backgroundColor: "#6FDA44",
            fontSize: "14px",
            width: "100%",
            borderRadius: "4px",
            textTransform: "inherit",
          }}
          onClick={clearFilter}
        >
          Clear All
        </Button>
      </Box>
    </Box>
  );
};

export default DashboardSidebarWeb;
