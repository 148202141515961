import { useRef, useEffect } from "react";

function Video({ url, className, handleOnPlayMedia, handleOnPauseMedia }) {
  const videoRef = useRef();

  useEffect(() => {
    videoRef.current?.load();
  }, [url]);

  return (
    <video
      className={className}
      ref={videoRef}
      controls="controls"
      autoPlay
      onPlay={handleOnPlayMedia}
      onPause={handleOnPauseMedia}
    >
      <source src={url} type="video/mp4" />
    </video>
  );
}

export default Video;
