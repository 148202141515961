import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import count1 from "../assets/count1.png";
import count2 from "../assets/count2.png";
import count3 from "../assets/count3.png";
import privatefilebg from "../assets/privatefilebg.jpeg";
import { GreenButton } from "../components/Styled";
import { AppContext } from "../Context";
import Loading from "../connectivityAssets/Loading";
import useNft from "../hooks/useNFT";
import useNftOwner from "../hooks/useNftOwner";
import useDocumentTitle from "../useDocumentTitle";
import { ORDER_STATUS } from "../utils/constant";
import ConnectionModal from "../components/Modal/ConnectModal";

const PrivateFile = () => {
  // conect
  const { isMobile, connectModal, toggleConnectModal, setAlertModal } = useContext(AppContext);
  useDocumentTitle("View this page in MetaMask browser | Purchase NFT to view this exclusive content ");

  const { id } = useParams();
  const { address } = useAccount();
  const [nft, , isLoading] = useNft(id);  
  const [owner] = useNftOwner(nft?.tokenId.toString());  
  
  const order = nft?.order?.length ? nft.orders[0] : null;

  return (
    <>
      <ConnectionModal connectModal={connectModal} toggleConnectModal={toggleConnectModal} />
      {isLoading && <Loading isLoading={isLoading} />}
        <Box backgroundColor="black">
          <Box
            sx={{
              backgroundImage: `url(${privatefilebg})`,
              // backgroundPosition: { sm: "center", xs: "left" },
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box py={20}>
              <Typography
                sx={{
                  fontSize: "37px",
                  color: "white",
                  fontWeight: "bold",
                  position: "relative",
                  "&:after": {
                    bottom: "3px",
                    color: "#6fda44",
                    content: "'„'",
                    fontFamily: "FontAwesome",
                    fontSize: "30px",
                    paddingLeft: "10px",
                    position: "absolute",
                    fontWeight: "bold",
                  },
                  "&:before": {
                    color: "#6fda44",
                    content: "'‟'",
                    fontFamily: "FontAwesome",
                    fontSize: "30px",
                    marginLeft: "-22px",
                    position: "absolute",
                    fontWeight: "bold",
                    top: "0",
                  },
                }}
              >
                Private File
              </Typography>{" "}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              my: 5,
            }}
          >
            <Box>
              <img src={count1} alt="" />
            </Box>
            <Typography
              my={2}
              sx={{
                fontSize: { md: "22px", xs: "16px" },
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Install Metamask browser extension
            </Typography>
            <a
              href="https://metamask.io/"
              target="/"
              style={{
                textDecoration: "none",
              }}
            >
              <GreenButton>Get Extension</GreenButton>
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              my: 5,
            }}
          >
            <Box>
              <img src={count2} alt="" />
            </Box>
            <Typography
              my={2}
              sx={{
                fontSize: { md: "22px", xs: "16px" },
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Purchas NFT
            </Typography>

            <a href="https://shop.sniser.com/" style={{ textDecoration: "none" }}>
              <GreenButton>Buy NFT</GreenButton>
            </a>

            {/* <Link to={`/nft/${id}`} style={{ textDecoration: "none" }}>
              <GreenButton>Buy NFT</GreenButton>
            </Link> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              py: 5,
            }}
          >
            <Box>
              <img src={count3} alt="" />
            </Box>
            <Typography
              my={2}
              sx={{
                fontSize: { md: "22px", xs: "16px" },
                color: "white",
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Connect your wallet to access
            </Typography>
            {(address && owner) || (order?.status === ORDER_STATUS.SALE) ? (
              <>
                {address === owner ? (
                  <Link to={nft?.fileType?.toLowerCase() === "book" ? `/nft-detail/${id}` : `/video-library/${id}`} style={{ textDecoration: "none" }}>
                    <GreenButton> Access now</GreenButton>
                  </Link>
                ) : (
                  <Link to={`/nft/${id}`} style={{ textDecoration: "none" }}>
                    <GreenButton> Buy NFT First To Access</GreenButton>
                  </Link>
                )}
              </>
            ) : (
              <GreenButton onClick={_ => toggleConnectModal(true)}>Connect Your Wallet</GreenButton>
            )}
          </Box>
        </Box>
    </>
  );
};

export default PrivateFile;
