import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useModal } from "../../modal-context";
import { secureToken, serverUrl } from "../../utils/constant";
import { post } from "../../utils/fetchApis";
import "./styles.scss";
import { setStorage } from "../../utils/helper";

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(8).max(32).required(),
});

const LoginModal = ({ open, close }) => {
  const { setModalSignUp, setModalLogin, setUserInfo } = useModal();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [user, setUser] = useState();

  const onSubmitHandler = (data) => {    

    post('auth/login', {email: data.email, password: data.password}).then(data => {
      setStorage('token', data.access_token)
      setUserInfo(data);
      setModalLogin(false);
    })
  };

  return (
    <Modal show={open} onHide={close}>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={close}>
        <span aria-hidden="true">
          <span className="back">
            <i className="fa fa-chevron-left" aria-hidden="true"></i>
            &nbsp;Back
          </span>
          <span className="x">x</span>
        </span>
      </button>
      <Modal.Body>
        <div className="row">
          <div className="modal-right-col">
            <div className="sniser-logo">
              <img src="https://sniser.com/images/sniser-logo.png" alt="" />
            </div>

            <h2>Welcome Back!</h2>
            <p className="desc">Connect with your audience through NFTs!</p>

            <form onSubmit={handleSubmit(onSubmitHandler)} className="login-form">
              <div className="form-group">
                <input type="email" className={`form-control ${errors?.email && "invalid"}`} placeholder="Email" {...register("email")} />
              </div>

              <div className="form-group">
                <input type="password" className={`form-control ${errors?.password && "invalid"}`} placeholder="Password" {...register("password")} />
              </div>

              {/* <div className="form-group">
                  <div className="g-recaptcha">
                    <div style={{ width: "304px", height: "78px" }}>
                      <div>
                        <ReCAPTCHA
                          sitekey={"6LdfLysUAAAAAIVunEmrVZGErd_74d1r7deXdksg"}
                          // onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

              <div className="form-group">
                <input type="submit" className="login-btn" name="loginsubmit" value="Login" />
              </div>

              <div className="form-group forget">
                <p>
                  <Link to={"/forgot-password"} onClick={close}>
                    Forget your Password ?
                  </Link>
                </p>
              </div>

              <div className="form-group">
                <p>
                  Don't have an account? <br />
                  <button
                    className="btnShowsSignUp"
                    onClick={() => {
                      setModalSignUp(true);
                      close();
                    }}
                  >
                    Sign Up Now!
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
