import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { Box, Button, Paper, styled, Typography, useMediaQuery } from "@mui/material";
import { GC_BUCKET_URL, ORDER_STATUS } from "../utils/constant";
import icon from "../assets/Ticon.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AppContext } from "../Context";
import { useAccount } from "wagmi";
import { NFT_STATUS } from "../utils/constant";
import { Link } from "react-router-dom";

const MyButton = styled(Button)({
  backgroundColor: "#6FDA44",
  color: "#2A3538",
  borderRadius: "4px",
  width: "100%",
  fontFamily: "'Montserrat', sans-serif",
  "&:hover": {
    backgroundColor: "#90E26F",
  },
  fontSize: "14px",
  fontWeight: "700",
  flex: 1,
});

const NftItemCard = ({ nft, list, buy }) => {

  const navigate = useNavigate();    
  const { isConnected, address } = useAccount();
  const { isMobile, toggleConnectModal, wallet } = useContext(AppContext);

  const soldOrders = nft.orders.filter((o) => o.status === ORDER_STATUS.SOLD);  
  const hot = !soldOrders.length;
  
  console.log('nft item', soldOrders)
  return (
    <Paper
      elevation={3}
      sx={{
        borderRadius: "10px",
        background: "#FFFFF",
        overflow: "hidden",
        position: 'relative',
        paddingBottom: '48px',
      }}
    >
      <Link
        to={`/nft/${nft.id}`}
        style={{
          textDecoration: "none",
          color: "black",
        }}
      >
        <Box
          sx={{
            background: hot ? "#2A3538" : "rgb(42, 53, 56,0.2)",
            zIndex: 1000,
            py: "4px",
          }}
        >
          <Typography textAlign={"center"} ml="12px" color={"white"} sx={{ fontWeight: "700 !important" }}>
            {hot ? "Original" : "Resale"}
          </Typography>
        </Box>

        <img src={`${GC_BUCKET_URL}${nft?.image}`} alt="nft" style={{ width: "100%", height: isMobile ? "200px" : "250px" }} />

        <Box p={{ xs: "10px", md: "20px" }}>
          <Typography
            fontSize={{
              xs: "24px",
              md: "20px",
              overflow: "hidden",
              display: "-webkit-box",
              WebkitLineClamp: "1",
              WebkitBoxOrient: "vertical",
            }}
            textAlign={{
              xs: "center",
              md: "left",
            }}
            fontWeight={"700"}
            color={"#2A3538"}
          >
            {nft?.title}
          </Typography>
          <Box mt="10px" display="flex" alignItems={"center"} justifyContent={{ xs: "center", md: "flex-start" }}>
            <img src={icon} width="24px" alt="seen icon" />
            <Typography color={"#2A3538"} fontSize={{ xs: "14px", md: "16px" }} fontWeight={"700"} px={"10px"}>
              {nft?.price}
            </Typography>
            <Typography color={"#2A3538"} fontSize={{ xs: "20px", md: "16px" }}>
              USDT
            </Typography>
          </Box>
          <Box display="flex" gap={2} mt={2} justifyContent={{ xs: "center", md: "flex-start" }}>
            <Box
              sx={{
                borderRadius: "5px",
                width: "48px",
                py: "4px",
                textAlign: "center",
                transition: "0.5s ease-in-out",
                border: "1px solid #2A3538",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#2A3538",
                }}
              >
                {nft?.fileType}
              </Typography>
            </Box>
          </Box>        
        </Box>
      </Link>
      

      <Box sx={{ 
        position: 'absolute', 
        left: 0, 
        right: 0, 
        bottom: 0, 
        display: "flex", 
        gap: "10px", 
        padding: '16px',
      }}>
          <MyButton my={2} fontSize={{ xs: "13px", md: "18px" }} onClick={() => {
            if (!isConnected) {              
              toggleConnectModal(true)
            } else {
              if (nft.walletId !== wallet?.id) {
                buy(nft);
              } else {
                list(nft);
              }
            }
          }}>
          {nft.walletId === wallet?.id ? nft.status === NFT_STATUS.SALE ? 'Edit list' : 'List for sale' : "Buy NFT"}
          </MyButton>
          <Box
            sx={{
              background: "#2A3538",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "41px",
              height: "41px",
              borderRadius: "4px",
            }}
          >
            <ShoppingCartOutlinedIcon color="primary" fontSize="small" />
          </Box>
        </Box>
    </Paper>
  );
};

export default NftItemCard;
