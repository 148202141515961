/* eslint-disable camelcase */
import { Box, Button, Container, Grid, IconButton, Menu, MenuItem, TablePagination, TextField, Typography, useMediaQuery } from "@mui/material";
import { ethers } from "ethers";
import React, { Suspense, lazy, useContext, useEffect, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import { AppContext } from "../Context";
import ListStatModal from "../components/Modal/ListStatModal";
import NftItemCard from "../components/NftItemCard";
import { buttonStyle, sorting } from "../components/Styled/style";
import Loading from "../connectivityAssets/Loading";
import useDocumentTitle from "../useDocumentTitle";
import { approveUSDC, buyNFT, getApprovedNFT, approveNFT, cancelItemForSale, getAllowanceUSDC, getNewOrderId, getUSDCBalance, putItemForSale, updateItemForSale } from "../utils/contract";
import { post, get, update } from "../utils/fetchApis";
import { DEV_MODE, LISTING, NFT_STATUS, ORDER_STATUS } from "../utils/constant";
import { config } from "../utils/wagiConfig";
import { getEthersSigner } from "../utils/ether";
import { isAxiosError } from "axios";
import { marketplaceContractAddr } from "../connectivityAssets/mintContract/addresses";
import { useSaleNfts } from "../hooks";

const DashboardSidebarWeb = lazy(() => import("../components/DashboardSidebarWeb"));

const ShopNft = () => {
  const matches = useMediaQuery("(max-width:600px)");
  const [loading, setLoading] = useState(false);
  const [sidebar, setSidebar] = useState(true);
  const [select, setSelect] = useState("Recent");
  const [rowsPerPage, setRowsPerPage] = React.useState(matches ? 3 : 6);
  const [page, setPage] = React.useState(0);
  const [nft, setNft] = useState(null);
  const [status, setStatus] = useState(LISTING.START);
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const { wallet, setAlertState } = useContext(AppContext);

  const {saleNfts, execute} = useSaleNfts()
  const [filterdNfts, setFilterdNfts] = useState();  
  const [keyword, setKeyword] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);    

  useDocumentTitle("View exclusive NFT protected content | Find files from upcoming artists");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };  

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const completeList = async (price) => {
    try {
      setStatus(LISTING.APPROVING);

      const signer = await getEthersSigner(config)
      const approved = await getApprovedNFT(nft.tokenId)
      if (approved !== marketplaceContractAddr) {
        await approveNFT(signer, nft.tokenId);
      }
      await putItemForSale(signer, nft.tokenId, ethers.utils.parseUnits(price, 6));

      const orderId = await getNewOrderId();

      const params = {
        marketplaceSaleId: parseInt(orderId) - 1,
        tokenId: nft.tokenId,
        price,
        status: ORDER_STATUS.SALE,
        walletId: wallet.id,
      };

      
      const {success, message } = await post(`orders/${nft.id}/create`, params);
      if (!success) {
        setAlertState({
          open: false,
          message,
          severity: "error",
        });
      }

      await execute();

      setStatus(LISTING.LIST_FINISH);
    } catch (e) {
      let message = 'something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const updateList = async (price) => {
    try {      
      setStatus(LISTING.APPROVING);

      const signer = await getEthersSigner(config)
      await updateItemForSale(signer, nft.order.marketplaceSaleId, ethers.utils.parseUnits(price, 6));
      const params = {        
        price,
      };
      
      await update(`orders/${nft.order.id}`, params);
      await execute();

      setStatus(LISTING.EDIT_FINISH);
    } catch (e) {
      let message = 'Something wrong happened'
      if (isAxiosError(e)) {        
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const cancelList = async (nft) => {
    try {
      setStatus(LISTING.CANCELING);
      const signer = await getEthersSigner(config)
      await cancelItemForSale(signer, nft.order.marketplaceSaleId);
      const params = {        
        status: ORDER_STATUS.CANCEL,
      };
      
      await update(`orders/${nft.order.id}`, params);
      await execute();

      setPriceModalOpen(false);
    } catch (e) {
      let message = 'Something wrong happened'
      if (isAxiosError(e)) {        
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const buyHandler = async (nft) => {
    try {
      setLoading(true);      
      
      const signer = await getEthersSigner(config)
      const nftPrice = ethers.utils.parseUnits(`${nft.price}`, 6);
      
      const usdcBalance = await getUSDCBalance(signer);
      
      if (nftPrice.gte(usdcBalance)) {
        setLoading(false);
        setAlertState({
          open: true,
          message: "Low USDT Balance",
          severity: "error",
        });
        return;
      }

      const allowance = await getAllowanceUSDC(signer);
      
      if (nftPrice.gt(allowance)) {        
        await approveUSDC(signer, nftPrice);
      }

      await buyNFT(signer, nft.order.marketplaceSaleId, nftPrice);
      const params = {        
        status: ORDER_STATUS.SOLD,
        walletId: wallet.id,
        marketplaceAddress: marketplaceContractAddr,
      };
      
      await update(`orders/${nft.order.id}`, params);
      await execute();

      setLoading(false);

      setAlertState({
        open: true,
        message: "Purchased NFT successfully!",
      });
    } catch (e) {
      let message = 'Something wrong happened'
      if (isAxiosError(e)) {        
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setLoading(false);
    }
  };

  const handleList = async (nft) => {
    setNft(nft);
    setStatus(nft.status === NFT_STATUS.SALE ? LISTING.EDIT : LISTING.START);
    setPriceModalOpen(true);
  };

  const handlefilter = (name) => {
    if (name === "Date (newest)") {
      setFilterdNfts(filterdNfts.sort((a, b) => Number(b.id) - Number(a.id)));
    } else if (name === "Date (oldest)") {
      setFilterdNfts(filterdNfts.sort((a, b) => Number(a.id) - Number(b.id)));
    } else if (name === "Price (low to high)") {
      setFilterdNfts(filterdNfts.sort((a, b) => Number(a.price) - Number(b.price)));
    } else if (name === "Price (high to low)") {
      setFilterdNfts(filterdNfts.sort((a, b) => Number(b.price) - Number(a.price)));
    }
  };

  const handleSearch = (e) => {
    setKeyword(e.target?.value);
    if (e.target?.value.length > 2) {
      setFilterdNfts(saleNfts.filter((f) => f?.title?.toLowerCase().includes(e.target?.value.toLowerCase())));
    }
    if (!e.target?.value) {
      setFilterdNfts(saleNfts);
    }
  };

  useEffect(() => {
    setFilterdNfts(saleNfts);
  }, [saleNfts]);
  
  return (
    <Box sx={{ background: "#6FDA44" }}>
      <Container>
        {loading && <Loading isLoading={loading}/>}
        <Box py="20px">
            {priceModalOpen && (
              <ListStatModal
                status={status}
                nft={nft}
                priceModalOpen={priceModalOpen}
                completeList={(price) => completeList(price)}
                updateList={(price) => updateList(price)}
                cancelList={cancelList}
                toggelModal={(_) => setPriceModalOpen(!priceModalOpen)}
              />
            )}
            
            <Grid container spacing={5} justifyContent="center">
              <Grid item md={sidebar ? 3 : 0} xs={sidebar ? 12 : 0}>
                <Suspense fallback={<Box> LOADING .... </Box>}>
                  <DashboardSidebarWeb 
                    setSidebar={setSidebar} 
                    sidebar={sidebar} 
                    setFilterdNfts={setFilterdNfts} 
                    filterdNfts={filterdNfts} 
                    allNfts={saleNfts} />
                </Suspense>
              </Grid>
              <Grid item md={sidebar ? 9 : 12} xs={12}>
                {/* ---------------------  view inner Grid---------------------------- */}
                <Grid container columnSpacing={2} mb="25px" rowSpacing={{ md: 0, xs: 2 }} justifyContent="center">
                  <Grid item md={sidebar ? 7.5 : 8.5} lg={sidebar ? 9 : 8} xs={12}>
                    <TextField
                      onChange={handleSearch}
                      sx={{
                        borderRadius: "8px",
                        ".MuiInputBase-root": { borderRadius: "8px" },
                        Input: {
                          borderRadius: "8px",
                          color: "#2A3538",
                          "&::placeholder": {
                            textOverflow: "ellipsis !important",
                            fontWeight: 500,
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#2A3538",
                          },
                        },
                        backgroundColor: "#ffffff",
                      }}
                      InputProps={{
                        startAdornment: (
                          <IconButton>
                            <SearchIcon sx={{ color: "#2A3538" }} />
                          </IconButton>
                        ),
                      }}
                      placeholder="Search Keyword"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={sidebar ? 4.5 : 3.5} lg={sidebar ? 3 : 2.5} display={{ xs: "none", md: "block" }} textAlign="center">
                    <Button
                      id="demo-customized-button"
                      aria-controls={open ? "demo-customized-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      variant="contained"
                      disableElevation
                      onClick={handleClick}
                      endIcon={<KeyboardArrowDownIcon />}
                      disableRipple
                      sx={buttonStyle}
                    >
                      {select}
                    </Button>
                    <Menu
                      id="demo-customized-menu"
                      MenuListProps={{
                        "aria-labelledby": "demo-customized-button",
                      }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={(_) => setAnchorEl(null)}
                    >
                      {sorting.map((name, i) => {
                        return (
                          <MenuItem
                            key={i}
                            onClick={() => {
                              setSelect(name);
                              handlefilter(name);
                              setAnchorEl(null);
                            }}
                            sx={{ color: "black" }}
                            disableRipple
                          >
                            {name}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={2} mb="25px" rowSpacing={{ md: 0, xs: 2 }}>
                  {keyword && (
                    <Grid item>
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "14px",
                          color: "#2A3538",
                        }}
                      >
                        Showing Result for {keyword} Keyword ({filterdNfts?.length} NFT’s)
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                {/* ---------------------  NFT inner Grid---------------------------- */}
                <Grid container spacing={{ xs: 1.5, md: 5 }} my="10px" justifyContent={"center"}>
                  {filterdNfts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((nft, i) => (
                    <Grid key={i} item md={4} xs={12} sm={4}>
                      <NftItemCard nft={nft} list={handleList} buy={buyHandler} />
                    </Grid>
                  ))}

                  {filterdNfts?.length === 0 && (
                    <Typography variant="h2" my={6}>
                      No Nfts Yet !
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <TablePagination
              labelRowsPerPage={<span> NFT&#39;s Per Page </span>}
              rowsPerPageOptions={matches ? [3, 6, 12] : [6, 12]}
              component="div"
              count={filterdNfts?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
      </Container>
    </Box>
  );
};

export default ShopNft;
