import { styled } from "@mui/material";
import Button from "@mui/material/Button";

const MyButton = styled(Button)({
  backgroundColor: "#6FDA44",
  color: "white",
  borderRadius: " 10px",
  "&:hover": {
    backgroundColor: "#F6F6f6",
    color: "black",
    transition: "1s",
  },
});
export default MyButton;
