import React, { useState } from "react";
import LoginModal from "./components/Modal/LoginModal";
import SignUpModal from "./components/Modal/SignUpModal";

const ModalContext = React.createContext();

const ModalProvider = (props) => {
  const [modalSignUp, setModalSignUp] = useState();
  const [modalLogin, setModalLogin] = useState();
  const [userInfo, setUserInfo] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <ModalContext.Provider
      value={{
        modalSignUp,
        setModalSignUp,
        modalLogin,
        setModalLogin,
        userInfo,
        setUserInfo,
        tabIndex,
        setTabIndex,
      }}
      {...props}
    >
      {props.children}
      {!!modalSignUp && <SignUpModal open={modalSignUp} close={() => setModalSignUp(false)} />}
      {!!modalLogin && <LoginModal open={modalLogin} close={() => setModalLogin(false)} />}
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error("useModal must be used within a UserProvider");
  }

  return context;
};

export { ModalProvider, useModal };
