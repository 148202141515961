import "./styles.scss";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { post } from "../../utils/fetchApis";
import { serverUrl } from "../../utils/constant";

const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const ForgotPassword = () => {
  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    
    post('auth/forgot_password', {email: data.email})
    .then(function (response) {
      
      setSuccess(true);
    });
  };  
  return (
    <>
      <div className="bredcrump-section">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Forget password
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div className="forget-pass">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 col-sm-12">
              <h3 className="title">Forgot Password </h3>
              <span>Enter your email address to continue </span>
              <div className="wrapper">
                {success && (
                  <div style={{ color: "white", backgroundColor: "#6FDA44" }} className="alert alert-danger">
                    <span>A link has been sent to {getValues("email")} .</span>
                    <br /> Please check your email and junk box to reset it.
                  </div>
                )}
                <form name="forgotfrm" className="forget-form login-form" onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="form-group">
                    <input type="email" className={`form-control ${errors?.email && "invalid"}`} placeholder="Email Address" {...register("email")} />
                  </div>
                  <div className="form-group">
                    <input type="submit" className=" login-btn" name="forgotfrm" value="Forgot my Password" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
