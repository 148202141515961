import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, Container, Typography } from "@mui/material";
import PurchaseAnNftImage1 from "../assets/load-more-concept-illustration.jpg";
import PurchaseAnNftImage2 from "../assets/investor-with-laptop-monitoring-growth-dividends-trader-sitting-stack-money-investing-capital-analyz.jpg";
import PurchaseAnNftImage3 from "../assets/hand-drawn-business-planning-concept.jpg";
import PurchaseAnNftImage from "../assets/landing-page-concept-illustration.jpg";
import { useNavigate } from "react-router-dom";

const ContentData = [
  {
    title: "A platform for exclusive content",
    description:
      "When someone purchases one of the NFT that shows your content, you will get a large percentage of the profits than from any streaming service. 90% of all profits are kept by you. You do not need to have a crypto wallet to receive payment as we can send payment to a valid PayPal account.",
    imageUrl: PurchaseAnNftImage1,
  },
  {
    title: "Real financial support for artists",
    description:
      "Artists get the lion share of profits from the sale of NFTs on this platform. Furthermore, artists also get commission from secondary sales of their NFTS. So even if content is sold 100 times, the original creator will receive royalty payments.",
    imageUrl: PurchaseAnNftImage2,
  },
  {
    title: "Based on blockchain technology",
    description:
      "Continue to upload content to mainstream sites like YouTube and Spotify. This will ensure that you get maximum exposure and brand recognition. However, upload exclusive content to our site that can only be purchased by people that have purchased your content linked NFT.",
    imageUrl: PurchaseAnNftImage3,
  },
];

function WhatIsSniser() {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ backgroundColor: "#6FDA44" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column-reverse", md: "row" },
              paddingY: { xs: "39px", sm: "63px" },
            }}
          >
            <Box sx={{ maxWidth: "477px" }}>
              <Typography variant="h2" sx={{ mb: "20px", fontSize: "32px !important", color: "#2A3538", textTransform: "uppercase" }}>
                What is Sniser?
              </Typography>
              <Typography sx={{ mb: "31px", fontWeight: "400 !important" }}>
                Sniser works by allowing content creators to upload content that can only be viewed by those with ownership of content linked NFTs. For our
                audience it is an exclusive method to purchase and view content that is not freely viewable from any other source. Once viewed, the owners of
                the NFT can either keep them or sell to others on this site at a profit.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Button
                  sx={{
                    background: "#2A3538",
                    fontWeight: 700,
                    color: "#ffffff",
                    textTransform: "capitalize",
                    padding: "10px 40px",
                  }}
                  onClick={() => navigate("/shop")}
                >
                  Buy Now
                </Button>
                <ArrowOutwardIcon sx={{ color: "#2A3538", fontSize: "24px" }} />
              </Box>
            </Box>
            <Box sx={{ width: { xs: "345px", md: "600px" }, height: { xs: "auto", md: "400px" }, mb: { xs: "23px", md: 0 } }}>
              <Box component={"img"} src={PurchaseAnNftImage} alt="" sx={{ objectFit: "cover", height: { xs: "auto", md: "100%" }, mb: 1 }} />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ paddingBottom: { xs: "63px", md: "99px" } }}>
        {ContentData?.map((item, index) => (
          <Box
            key={index.toString()}
            sx={{
              paddingY: { xs: "23px", md: "52px" },
              display: "flex",
              gap: "62px",
              flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
              backgroundColor: index % 2 === 0 ? "transparent" : "rgba(2, 3, 3, 0.05)",
            }}
          >
            <Container>
              <Box
                key={index.toString()}
                sx={{
                  paddingY: { xs: "23px", md: "40px" },
                  display: "flex",
                  gap: "62px",
                  flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
                }}
              >
                <Box sx={{ width: { xs: "345px", md: "450px" }, height: "auto", mb: { xs: "23px", md: 0 }, flexShrink: 0 }}>
                  <img src={item.imageUrl} alt="" style={{ objectFit: "cover" }} />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "64px", color: "#6FDA44" }}>{index + 1}.</Typography>
                  <Typography sx={{ fontSize: "32px", color: "#2A3538", textTransform: "uppercase" }}>{item.title}</Typography>
                  <Typography sx={{ fontWeight: "400 !important", color: "#2A3538" }}>{item.description}</Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default WhatIsSniser;
