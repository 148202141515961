import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, Container, Typography } from "@mui/material";
import CoinImage1 from "../assets/crypto-portfolio-concept-illustration.jpg";
import CoinImage from "../assets/app-monetization-concept-illustration.jpg";
import CoinImage3 from "../assets/marketing-consulting-concept-illustration.jpg";
import CoinImage2 from "../assets/rockers-concept-illustration.jpg";
import useDocumentTitle from "../useDocumentTitle";
import { useNavigate } from "react-router-dom";

const ContentData = [
  {
    title: "What is a dividend token?",
    description:
      "A token is a digital asset that is gained from a blockchain network. The token can be given and received for several reasons such as undertaking an activity on the network or as a form of accepted payment between individuals. A dividend token is an asset that gives a reward to its owners. In some respects, a token is like a “share” and the dividend is like a “share dividend”. However, unlike normal shares, there is no intrinsic value to the dividend beyond what sellers and buyers believe it to be worth.",
    imageUrl: CoinImage1,
  },
  {
    title: "Supporting artists:",
    description:
      "Streaming and less people purchasing CD and DVDs, has lowered the revenues received by artists. As a result, artists receive less financial support to turn their artistic ideas into reality. This is especially the case for new and independent artists, who will not be able to get significant support to complete films and music. By providing fiat currency in exchange for Sniser tokens, we can support new artists to build a portfolio or in other instances, purchase the rights to existing content and make it exclusively available on our platform. ",
    imageUrl: CoinImage2,
  },
  {
    title: "Regular token dividends:",
    description:
      "Aside from helping artists to bring their content to “life”, the Sniser token gives rewards to its owners who have contributed towards the success of artists. Whenever, an NFT is first sold on our marketplace, token owners share in the revenue generated. So, for example, if 10 people purchase a NFT and revenue of 100 tether is generated, those that own a Sniser token will receive a percentage of the 100 tether. Thus, the Sniser token is what is known as a “dividend token” and dividends are automatically transferred to token holders by way of an automated smart contract.",
    imageUrl: CoinImage3,
  },
];

function Coin() {
  useDocumentTitle("Sniser provides NFT protected content to ensure artist payment.");
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ backgroundColor: "#6FDA44" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column-reverse", md: "row" },
              paddingY: { xs: "39px", sm: "63px" },
            }}
          >
            <Box sx={{ maxWidth: "477px" }}>
              <Typography variant="h2" sx={{ mb: "20px", fontSize: "32px !important", color: "#2A3538" }}>
                SNISER COIN
              </Typography>
              <Typography sx={{ mb: "31px", fontWeight: "400 !important" }}>
                To support artists financially and bring their creations to life, we have developed the Sniser token. In exchange for fiat currency, we give
                people the sniser token. The fiat currency is then used to fund artists to develop content that is then listed exclusively on our site as NFT
                linked content. When that content is purchased, the people in possession of our Sniser token receive a percentage of the revenues.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Button
                  sx={{
                    background: "#2A3538",
                    fontWeight: 700,
                    color: "#ffffff",
                    textTransform: "capitalize",
                    padding: "10px 40px",
                  }}
                  onClick={() => navigate("/shop")}
                >
                  Invest Now
                </Button>
                <ArrowOutwardIcon sx={{ color: "#2A3538", fontSize: "24px" }} />
              </Box>
            </Box>
            <Box sx={{ width: { xs: "345px", md: "600px" }, height: { xs: "auto", md: "400px" }, mb: { xs: "23px", md: 0 } }}>
              <Box component={"img"} src={CoinImage} alt="" sx={{ objectFit: "cover", height: { xs: "auto", md: "100%" }, mb: 1 }} />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ paddingBottom: { xs: "63px", md: "99px" } }}>
        {ContentData?.map((item, index) => (
          <Box
            key={index.toString()}
            sx={{
              paddingY: { xs: "23px", md: "52px" },
              display: "flex",
              gap: "62px",
              flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
              backgroundColor: index % 2 === 0 ? "transparent" : "rgba(2, 3, 3, 0.05)",
            }}
          >
            <Container>
              <Box
                key={index.toString()}
                sx={{
                  paddingY: { xs: "23px", md: "40px" },
                  display: "flex",
                  gap: "62px",
                  flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
                }}
              >
                <Box sx={{ width: { xs: "100%", md: "450px" }, height: "auto", mb: { xs: "23px", md: 0 }, flexShrink: 0 }}>
                  <img src={item.imageUrl} alt="" style={{ objectFit: "cover" }} />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "64px", color: "#6FDA44" }}>{index + 1}.</Typography>
                  <Typography sx={{ fontSize: "32px", color: "#2A3538", textTransform: "uppercase" }}>{item.title}</Typography>
                  <Typography sx={{ fontWeight: "400 !important", color: "#2A3538" }}>{item.description}</Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default Coin;
