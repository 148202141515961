import { Container } from "@mui/material";
import React from "react";
import useDocumentTitle from "../useDocumentTitle";

export default function UploadTermConditions() {
  useDocumentTitle(
    "View exclusive NFT protected content | Find files from upcoming artists",
  );

  return (
    <Container sx={{ py: "20px" }}>
      <p>
        <span style={{ fontSize: "11pt" }}>Terms of uploading content:</span>
      </p>
      <p>
        <br />
      </p>
      <ol>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              All personal information held by us is subject to the data
              protection and GDPR law of England and Wales.&nbsp;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              By uploading content to this site, you confirm that you have
              copyright ownership to all content.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              You agree that any content uploaded here is appropriate to be
              viewed by any person of 13 years and older. &nbsp;We reserve the
              right to determine if it is age appropriate or not.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              Uploading does not confer the right to have content on this site.
              It gives us the right to view said content. It is at our
              discretion to upload or reject any content.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              You agree to give us exclusive usage of material uploaded to this
              site. Once accepted, it cannot be reposted anywhere else without
              any express permission in writing.&nbsp;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              We reserve the right to reproduce portions of your content in
              order to facilitate and encourage sales.&nbsp;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              In exchange for this right, we agree to compensate you based on
              the stated price that you give for each NFT.&nbsp;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              We reserve the right to alter this price, in order to generate
              sales. Any payment to you will be reflected in this new
              price.&nbsp;
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              Payment will be 90% of the revenue received from the sale of the
              NFT minus any costs resulting from transferring crypto assets into
              fiat currency. To see current costs of changing Tether USD into
              fiat currency, please click&nbsp;
            </span>
            <a href="https://www.coinbase.com/en-gb/converter/usdt/usd#:~:text=The%20current%20value%20of%201,rate%20has%20increased%20by%200.11%25.">
              <u>
                <span style={{ color: "#0563c1", fontSize: "11pt" }}>here</span>
              </u>
            </a>
            <span style={{ fontSize: "11pt" }}>.</span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              Upon payment to us for your NFT linked content, we will pay you to
              the PayPal account that you have provided us with. Any failure to
              provide us with the correct information, resulting in incorrect
              payment, will be at your own fault. We will not be liable to make
              payment where this is the case.
            </span>
          </p>
        </li>
        <li style={{ listStyleType: "decimal", fontSize: "11pt" }}>
          <p>
            <span style={{ fontSize: "11pt" }}>
              If required as a matter of criminal law, we will provide
              information to the authorities.
            </span>
          </p>
        </li>
      </ol>
    </Container>
  );
}
