import { Box, Button, Grid, TextField, TextareaAutosize, Typography, styled } from "@mui/material";
import { useContext, useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { AppContext } from "../Context";
import Loading from "../connectivityAssets/Loading";
import { post } from "../utils/fetchApis";
import usePlans from "../hooks/usePlans";
import ConfirmPaypalSubscriptionModal from "../components/Modal/ConfirmPaypalSubscriptionModal";

const CustomButton = styled(Button)({
  textAlign: "center",
  background: "#F6F6F6",
  textTransform: "capitalize",
  padding: "8px 18px",
  borderRadius: "7px",
  width: "100%",
  "&:hover": {
    backgroundColor: "#6FDA44",
    color: "white",
  },
});

const textareStyle = {
  borderRadius: "7px",
  width: "100%",
  border: "1px solid #ccc"
  // backgroundColor: "#F6F6F6",
};

const inputStyle = {
  borderRadius: "7px",
  Input: {
    borderColor: "#6fda44",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "21px",
      color: "#AAAAAA",
    },
  },

  // backgroundColor: "#F6F6F6",
};

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();  
  const { userData, setTabIndex } = useContext(AppContext);  
  const {plans} = usePlans()
  const options = useMemo(() => countryList().getData(), [])
  
  const { setAlertState } = useContext(AppContext);
  const [plan, setPlan] = useState()
  const [loading, setLoading] = useState(false);
  const [confirmLink, setConfirmLink] = useState(false);
  const [errors, setErrors] = useState({})
  const [links, setLinks] = useState([])

  const [form, setForm] = useState({
    planId: "",
    firstname: "",
    lastname: "",
    address: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    comments: "",
    discountCode: ""
  })
  
  const [finalAmount, setFinalAmount] = useState("");

  useEffect(() => {    
    if (location?.state?.planId) {
      setPlan(plans.find(p => p.id == location.state.planId))
      setForm({...form, planId: location.state.planId})
    }
  }, [plans]);

  let date = new Date();
  date.setDate(date.getDate() + 31);
  let dateString = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2);

  const handleFormChange = (e) => {
    setForm({...form, [e.target.name]: e.target.value})
  }

  const validateForm = () => {
    let errors = {}

    if (!form.planId) {
      console.log(`You doesn't have planId`)
    }
    if (!form.firstname) {
      errors.firstname = "First name is required"
    }
    if (!form.lastname) {
      errors.lastname = "Last name is required"
    }
    if (!form.address) {
      errors.address = "Full address is required"
    }
    if (!form.country) {
      errors.country = "Country is required"
    }
    if (!form.state) {
      errors.state = "State is required"
    }
    if (!form.city) {
      errors.city = "City is required"
    }
    if (!form.zipcode) {
      errors.zipcode = "Zip code is required"
    }

    return errors
  }

  const handlePayment = async (e) => {

    try {             
      
      const errors = validateForm()
      if (Object.keys(errors).length) {
        setErrors(errors)
        return;
      }
      setLoading(true);
      
      const url = userData.subscription && userData.plan.name !== 'artist' ? 'subscription/upgrade' : 'subscription'
      
      const {success, data, message} = await post(url, {
        ...form,
        country: form.country.value,
      })
      
      if (!success) {
        setAlertState({
          open: false,
          message,
          serverity: 'error',
        })
      }       

      setLinks(data)
      setLoading(false)
      setConfirmLink(true)
    } catch (error) {
      console.error(error);
    }
  };  

  const approveLink = async() => {
    window.open(links[0].href, '_blank')
    setTabIndex(2)
    navigate('/myaccount')
  }  

  console.log(links)
  return (
    <>
      {loading && <Loading isLoading={loading} />}
      
      <Box py="20px" px={{ md: "120px", xs: "10px" }}>
        <Typography variant="h1" fontFamily="'Montserrat', sans-serif" textAlign={{ md: "left", xs: "center" }}>
          Checkout
        </Typography>

        <Grid component="form" container justifyContent="center" spacing={3}>
          <Grid item md={6} xs={12}>
            <Typography py={2} variant="h3" fontFamily="'Montserrat', sans-serif" textAlign="left">
              Billing Details
            </Typography>

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" py={1}>
                  FIRST NAME
                </Typography>
                <TextField                  
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                  sx={inputStyle}
                  type="text"
                  placeholder="Your first name"
                  value={form.firstname}
                  name="firstname"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" py={1}>
                  LAST NAME
                </Typography>
                <TextField
                  error={!!errors.lastname}
                  helperText={errors.lastname}
                  sx={inputStyle}
                  placeholder="Your last name"
                  value={form.lastname}
                  name="lastname"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
            </Grid>            

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" py={1}>
                  FULL ADDRESS
                </Typography>
                <TextField
                  error={!!errors.address}
                  helperText={errors.address}
                  sx={inputStyle}
                  type="text"
                  placeholder="Your full address"
                  value={form.address}
                  name="address"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" py={1}>
                  COUNTRY
                </Typography>
                
                <Select options={options} value={form.country} onChange={value => setForm({...form, country: value})} />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" py={1}>
                  STATE
                </Typography>
                <TextField 
                  error={!!errors.state}
                  helperText={errors.state}
                  sx={inputStyle} 
                  placeholder="Youir state" 
                  value={form.state} 
                  name="state"
                  size="small" 
                  fullWidth 
                  onChange={handleFormChange}
                  required />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" py={1}>
                  CITY
                </Typography>
                <TextField
                  error={!!errors.city}
                  helperText={errors.city}
                  sx={inputStyle}
                  type="text"
                  placeholder="Your city"
                  value={form.city}
                  name="city"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" py={1}>
                  ZIP CODE
                </Typography>
                <TextField
                  error={!!errors.zipcode}
                  helperText={errors.zipcode}
                  sx={inputStyle}
                  placeholder="Your zipcode"
                  value={form.zipcode}
                  name="zipcode"
                  size="small"
                  fullWidth
                  onChange={handleFormChange}
                  required
                />
              </Grid>
            </Grid>

            <Grid container justifyContent="center" spacing={2} py={1}>
              <Grid item xs={12} md={12}>
                <Typography variant="h4" fontFamily="'Montserrat', sans-serif" py={1}>
                  ADDITIONAL COMMENTS
                </Typography>
                <TextareaAutosize 
                  error={!!errors.firstname}
                  helperText={errors.firstname}
                  minRows={11} 
                  style={textareStyle} 
                  name="comments"
                  value={form.comments} 
                  onChange={handleFormChange} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <Box style={{ padding: "6px 20px" }}>
              <Box
                style={{
                  boxShadow: "1px 1px 8px grey",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                <Typography
                  py={2}
                  px={2}
                  variant="h3"
                  fontFamily="'Montserrat', sans-serif"
                  textAlign="center"
                  sx={{
                    backgroundColor: "#6fda44",
                    color: "white",
                    borderTopRightRadius: "10px",
                    borderTopLeftRadius: "10px",
                  }}
                >
                  Your Order
                </Typography>

                <Typography py={2} px={2} variant="h4" fontFamily="'Montserrat', sans-serif" textAlign="left">
                  SUMMARY
                </Typography>

                <Box px={2}>
                  {plan?.description?.map((it) => {
                    return (
                      <Box px={2}>
                        - <Typography 
                          variant="overline"
                          fontFamily="'Montserrat', sans-serif" 
                          fontWeight={"400 !important"}
                        >
                          {it}
                        </Typography>
                      </Box>
                    );
                  })}                  
                </Box>

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={{
                    xs: "row",
                    md: "row",
                  }}
                  rowGap={"10px"}
                  alignItems={"center"}
                  mt={2}
                  px={2}
                >
                  <Box>
                    <Typography 
                      variant="h3" 
                      fontFamily="'Montserrat', sans-serif" 
                      textAlign="left"
                      textTransform="capitalize"
                    >
                      {plan?.name}
                    </Typography>

                    <Typography
                      fontFamily="'Montserrat', sans-serif"
                      textAlign="left"
                      style={{
                        color: "#AAAAAA",
                        paddingTop: "6px",
                        fontSize: "14px",
                      }}
                    >
                      {/* For record labels to showcase artist <br /> material */}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontFamily="'Montserrat', sans-serif" textAlign="right" style={{ color: "#6fda44", paddingTop: "6px" }}>
                      <span style={{ fontWeight: "bolder" }}> ${plan?.price} / </span>
                      month
                    </Typography>
                  </Box>
                </Box>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={{
                    xs: "row",
                    md: "row",
                  }}
                  rowGap={"10px"}
                  alignItems={"center"}
                  mt={2}
                  px={2}
                  gap={"10px"}
                >
                  {/* <Typography variant="h3" fontFamily="'Montserrat', sans-serif" textAlign="left">
                    DISCOUNT
                  </Typography>
                  <TextField
                    sx={inputStyle}
                    type="text"
                    placeholder="Discount code"
                    value={form.discountCode}
                    name="discountCode"
                    size="small"
                    fullWidth
                    onChange={handleFormChange}
                  />
                  <Button
                    sx={{
                      backgroundColor: "#6fda44",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#6FDA44",
                        color: "white",
                      },
                    }}
                    onClick={handleApplyDiscountCode}
                    disabled={true}
                  >
                    Apply
                  </Button> */}
                </Box>

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  flexDirection={{
                    xs: "row",
                    md: "row",
                  }}
                  rowGap={"10px"}
                  alignItems={"center"}
                  mt={2}
                  px={2}
                  py={3}
                  style={{ backgroundColor: "#f6f6f6" }}
                >
                  <Box>
                    <Typography variant="h3" fontFamily="'Montserrat', sans-serif" textAlign="left">
                      Total
                    </Typography>
                  </Box>
                  <Box>
                    <Typography fontFamily="'Montserrat', sans-serif" textAlign="right" style={{ color: "#6fda44", paddingTop: "6px" }}>
                      <span style={{ fontWeight: "bolder" }}> {finalAmount || finalAmount === 0 ? `$${finalAmount}` : plan?.price} / </span>
                      month
                    </Typography>

                    <Typography fontFamily="'Montserrat', sans-serif" textAlign="left" style={{ color: "#AAAAAA", fontSize: "14px" }}>
                      First renewal: {dateString}
                    </Typography>
                  </Box>
                </Box>

                {/* <Typography py={2} px={2} variant="h4" fontFamily="'Montserrat', sans-serif" textAlign="left">
                  PAYMENT METHOD
                </Typography> */}

                <Box py={2} px={2}>                  
                  <CustomButton
                    sx={{
                      marginTop: "20px",
                      width: "100%",
                      fontFamily: "'Montserrat', sans-serif",
                      backgroundColor: "#6fda44",
                      color: "white",
                    }}
                    onClick={handlePayment}
                    loading={loading}
                    disabled={loading}
                  >
                    Complete Order
                  </CustomButton>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <ConfirmPaypalSubscriptionModal
        open={confirmLink}
        cancel={_ => setConfirmLink(false)}
        confirm={approveLink}
      />
    </>
  );
};

export default Checkout;
