import { useState, useEffect, useCallback, useContext } from 'react'
import { post, get } from '../utils/fetchApis';
import { AppContext } from '../Context';

function usePlans() {
    const [loading, setLoading] = useState(false)
    const [plans, setPlans] = useState([])
    const { setAlertState } = useContext(AppContext);

    const getPlans = useCallback(async () => {        
        try {     
            setLoading(true)
            const {success, data, message}  = await get('subscription/plans')

            if (!success) {
                setAlertState({
                  open: true,
                  message,
                  severity: "error",
                })
            }
            setPlans(data)
        } catch (error) {        
        }
      }, []);

    useEffect(() => {        
        getPlans()
    }, [])
    
    return {plans, loading}
}

export default usePlans
