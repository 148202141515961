/* eslint-disable camelcase */
import { ContentCopy, DoneAll } from "@mui/icons-material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DescriptionIcon from "@mui/icons-material/Description";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { ethers } from "ethers";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useAccount, useSigner } from "wagmi";
import { AppContext } from "../../Context";
import Logo from "../../assets/logo-media.png";
import Ticon from "../../assets/ticon-circle.png";
import Loading from "../../connectivityAssets/Loading";
import { marketplaceContractAddr } from "../../connectivityAssets/mintContract/addresses";
import { DEV_MODE, LISTING, NFT_STATUS, ORDER_STATUS } from "../../utils/constant";

import {
  approveNFT,
  approveUSDC,
  buyNFT,
  cancelItemForSale,
  getAllowanceUSDC,  
  getNewOrderId,
  getUSDCBalance,
  putItemForSale,
  getApprovedNFT,
  updateItemForSale,
} from "../../utils/contract";
import { post, get, update } from "../../utils/fetchApis";
import ConnectionModal from "../Modal/ConnectModal";
import { ViewButton } from "../Styled";
import useNft from "../../hooks/useNFT";
import ListStatModal from "../Modal/ListStatModal";
import { getEthersSigner } from "../../utils/ether";
import { config } from "../../utils/wagiConfig";
import { GC_BUCKET_URL, FILE_TYPE } from "../../utils/constant";
import { isAxiosError } from "axios";

const StyledLink = styled(Typography)({
  fontWeight: 700,
  transition: "0.4s",
  cursor: "pointer",
  borderBottom: "none !important",
  textDecoration: "none !important",
});

const GreenButton = styled(Button)({
  backgroundColor: "#6FDA44",
  color: "#2A3538",
  width: "100%",
  "&:hover": {
    backgroundColor: "#6FDA44",
  },
  fontSize: "14px",
  fontWeight: "700",
});

const NFTDetail = () => {
  const params = useParams();
  const videoRef = useRef();
  const logoRef = useRef(null);
  const navigate = useNavigate();  

  const [status, setStatus] = useState(LISTING.START);

  //======== util states
  const [copy, setCopy] = React.useState(false);
  const [priceModalOpen, setPriceModalOpen] = useState(false);

  //connect
  const { isConnected, address } = useAccount();
  const { wallet, connectModal, setAlertState, toggleConnectModal } = useContext(AppContext);  
  
  const [loading, setLoading] = useState(false);
  const {nft, execute, isLoading} = useNft(params?.id);  
  
  const handleOnPlayMedia = () => {
    videoRef.current.classList.add("spin");
  };

  const handleOnPauseMedia = () => {
    videoRef.current.classList.remove("spin");
  };

  const toggelModal = () => {
    setPriceModalOpen(!priceModalOpen);
  };
  
  useEffect(() => {
    videoRef.current?.load();
  }, [nft]);

  const completeList = async (price) => {
    try {      
      const signer = await getEthersSigner(config)
      setStatus(LISTING.APPROVING);

      const approved = await getApprovedNFT(nft.tokenId)
      if (approved !== marketplaceContractAddr) {
        await approveNFT(signer, nft.tokenId);
      }
      
      await putItemForSale(signer, nft.tokenId, ethers.utils.parseUnits(price, 6));

      const orderId = await getNewOrderId();

      const params = {
        marketplaceSaleId: parseInt(orderId) - 1,
        marketplaceAddress: marketplaceContractAddr,
        tokenId: nft.tokenId,
        price,
        status: ORDER_STATUS.SALE,
        walletId: wallet.id
      };

      
      await post(`orders${nft.id}/create`, params);
      await execute();      
      setStatus(LISTING.LIST_FINISH);
    } catch (e) {
      let message = 'something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const updateList = async (price) => {
    try {      
      const signer = await getEthersSigner(config)
      setStatus(LISTING.APPROVING);      

      await updateItemForSale(signer, nft.order.marketplaceSaleId, ethers.utils.parseUnits(price, 6));
      const params = {        
        price,
      };      
 
      await post(`orders/${nft.order.id}`, params);
      await execute();

      setStatus(LISTING.EDIT_FINISH);
    } catch (e) {
      let message = 'something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const cancelList = async (nft) => {
    try {
      const signer = await getEthersSigner(config)
      setStatus(LISTING.CANCELING);
      const order = nft.orders[0];

      await cancelItemForSale(signer, order.marketplaceSaleId);
      const params = {        
        status: ORDER_STATUS.CANCEL,
      };
      
      await update(`orders/${order.id}`, params);
      await execute();      

      setPriceModalOpen(false);
    } catch (e) {

      let message = 'Something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  //====================== buy transaction=========================
  const buyHandler = async () => {
    try {
      setLoading(true);
      const signer = await getEthersSigner(config)      
      const nftPrice = ethers.utils.parseUnits(nft.price.toString(), 6);
      const usdcBalance = await getUSDCBalance(signer);

      // console.log(parseInt(nftPrice), parseInt(usdcBalance))
      if (parseInt(nftPrice) > parseInt(usdcBalance)) {
        setLoading(false);
        setAlertState({
          open: true,
          message: "Low USDT Balance",
          severity: "error",
        });
        return;
      }

      const allowance = await getAllowanceUSDC(signer);

      if (nftPrice.gte(allowance)) {
        await approveUSDC(signer, nftPrice);
      }

      await buyNFT(signer, nft.order.marketplaceSaleId, nftPrice);
      const params = {        
        status: ORDER_STATUS.SOLD,
        walletId: wallet.id,
        marketplaceAddress: marketplaceContractAddr,
      };
      
      await update(`orders/${nft.order.id}`, params);
      await execute();

      setLoading(false);

      setAlertState({
        open: true,
        message: "Purchased NFT successfully!",
      });
    } catch (e) {

      let message = 'Something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setLoading(false);
    }
  };

  const handleClick = () => {    

    if (nft.walletId !== wallet.id) {
      buyHandler();
    } else {
      setStatus(nft.status === NFT_STATUS.SALE ? LISTING.EDIT : LISTING.START);
      setPriceModalOpen(true);
    }
  };

  const btnLabel = () => {
    return nft.walletId === wallet?.id ?
      nft.status === NFT_STATUS.SALE ? 'Edit list' : 'List for sale' : 
      "Buy nft";
  };  
  const soldOrders = nft?.orders?.filter((o) => o.status === ORDER_STATUS.SOLD);
  const hot = nft.status === NFT_STATUS.SALE && !soldOrders.length;

  console.log(nft)
  const BreadCrumb = () => (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      backgroundColor="#192022"
      style={{
        zIndex: "1000px",
      }}
      height="50px"
      width="100%"
    >
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="cneter" alignItems="center">
          <Box mx={{ md: 4, xs: 2 }}>
            <NavLink to="/" style={({ isActive }) => (isActive ? { color: "black", textDecoration: "none" } : { color: "black", textDecoration: "none" })}>
              <StyledLink fontSize={{ md: "16px", xs: "12px" }} sx={{ fontWeight: 400 }} color={"white"}>
                Home
              </StyledLink>
            </NavLink>
          </Box>
          <Box display={{ xs: "inline-flex" }} mr={4}>
            <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "#fff" }} />
          </Box>
          <Box mr={{ md: 4, xs: 2 }}>
            <StyledLink fontSize={{ md: "16px", xs: "12px" }} sx={{ fontWeight: 400 }} color={"white"} onClick={() => navigate("/shop")}>
              Shop
            </StyledLink>
          </Box>
          <Box display={{ xs: "inline-flex" }} mr={4}>
            <ArrowForwardIosIcon sx={{ fontSize: "16px", color: "#fff" }} />
          </Box>
          <Box mr={{ md: 4, xs: 2 }}>
            <StyledLink fontSize={{ md: "16px", xs: "12px" }} fontWeight="700" color={"white"}>
              {nft?.title}
            </StyledLink>
          </Box>
        </Box>
      </Container>
    </Box>
  );

  return (
    <>
      <BreadCrumb />
      <Box>
        {loading || isLoading && <Loading isLoading={loading || isLoading} />}
        <>
            {priceModalOpen && (
              <ListStatModal
                status={status}
                nft={nft}
                priceModalOpen={priceModalOpen}
                toggelModal={toggelModal}
                completeList={(price) => completeList(price)}
                updateList={(price) => updateList(price)}
                cancelList={cancelList}
              />
            )}
            <ConnectionModal connectModal={connectModal} toggleConnectModal={toggleConnectModal} />            
            <Box sx={{ backgroundColor: "#6FDA44", pt: "50px" }}>
              <Container maxWidth="lg">
                <Grid container spacing={5} py={3}>
                  <Grid item md={4} xs={12}>
                    <Box
                      sx={{
                        borderRadius: "8px",
                        overflow: "hidden",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Box
                        sx={{
                          background: hot ? "#2A3538" : "rgb(42, 53, 56,0.2)",
                          zIndex: 1000,
                          py: "4px",
                        }}
                      >
                        <Typography textAlign={"center"} ml="12px" color={hot ? "white" : "#2A3538"}>
                          {hot ? "Original" : "Resale"}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", justifyContent: "center" }}>
                        <img src={`${GC_BUCKET_URL}${nft?.image}`} alt="nft" style={{ width: "100%", height: "auto", objectFit: "cover" }} />
                      </Box>

                      <Box sx={{ paddingX: "32px" }}>
                        <Typography
                          sx={{
                            fontSize: "32px",
                            fontWeight: 700,
                            color: "#2A3538",
                            wordBreak: "break-all",
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {nft?.title}
                        </Typography>
                        <Box mt="10px" display="flex" alignItems={"center"}>
                          <img src={Ticon} width="24px" alt="seen icon" />
                          <Typography color={"#2A3538"} fontSize={{ xs: "14px", md: "16px" }} fontWeight={"700"} px={"10px"}>
                            {nft?.price}
                          </Typography>
                          <Typography color={"#2A3538"} fontSize={{ xs: "12px", md: "16px" }}>
                            USDT
                          </Typography>
                        </Box>
                        <Divider
                          sx={{
                            my: "20px",
                            background: "#2A3538",
                            width: "100%",
                          }}
                        />
                        <Box mt="10px" display="flex" alignItems={"center"}>
                          <Typography fontSize="16px" fontWeight="700">
                            Contract Address:
                          </Typography>
                          <Typography fontSize="16px" pl={"5px"}>
                            {nft?.contractAddress?.slice(0, 4) + "..." + nft?.contractAddress?.slice(-4)}
                          </Typography>
                          <Tooltip title={copy ? "coppied" : "copy"} placement="top">
                            <IconButton
                              onClick={() => {
                                navigator.clipboard.writeText(nft?.contractAddress);
                                setCopy(true);
                              }}
                            >
                              {copy ? <DoneAll /> : <ContentCopy />}
                            </IconButton>
                          </Tooltip>
                        </Box>
                        <Box mt="10px" display="flex" alignItems={"center"}>
                          <Typography fontSize="16px" fontWeight="700">
                            NFT ID:
                          </Typography>
                          <Typography fontSize="16px" pl={"5px"}>
                            #{nft?.tokenId}
                          </Typography>
                        </Box>
                        <Box display="flex" gap={2} mt={2} mb={4}>
                          <Typography fontSize="16px" fontWeight="700">
                            Type:
                          </Typography>
                          <Box
                            sx={{
                              borderRadius: "5px",
                              width: "48px",
                              py: "4px",
                              textAlign: "center",
                              transition: "0.5s ease-in-out",
                              border: "1px solid #2A3538",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#2A3538",
                              }}
                            >
                              {nft?.fileType}
                            </Typography>
                          </Box>
                        </Box>
                        <Stack mb="37px">
                          <GreenButton fontSize={{ xs: "13px", md: "18px" }} onClick={handleClick}>
                            {btnLabel()}
                          </GreenButton>
                        </Stack>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box display="flex" justifyContent="center" alignItems="center" my={3} mt={0}>
                      <Accordion
                        sx={{
                          width: "100%",
                          background: "#F6F6F6",
                          boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.160784)",
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontWeight: 900 }} />} aria-controls="panel1a-content" id="panel1a-header">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DescriptionIcon sx={{ mr: 2 }} />
                            <Typography textAlign="center" sx={{ fontSize: "20px", fontWeight: "bold" }}>
                              Preview
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            borderTop: "2px solid #6FDA44",
                            backgroundColor: "#FFFFFF",
                            p: "8px 0 16px",
                          }}
                        >
                          {nft?.preview &&                            
                            <Grid                              
                              item
                              container
                              spacing={2}
                              style={{
                                position: "relative",
                                width: "100%",
                                margin: 0,
                              }}
                            >
                              <Grid xs={12}>
                                <video
                                  className={`media-player ${nft.fileType === FILE_TYPE.VIDEO}`}
                                  style={{ width: "100%" }}
                                  ref={videoRef}
                                  controls="controls"
                                  onPlay={handleOnPlayMedia}
                                  onPause={handleOnPauseMedia}
                                >
                                  <source src={GC_BUCKET_URL + nft.preview} type="video/mp4" />
                                </video>
                                {nft.fileType === FILE_TYPE.AUDIO && <img src={Logo} alt="" className="logo-media" ref={logoRef} />}
                              </Grid>
                            </Grid>
                          }
                        </AccordionDetails>
                      </Accordion>
                    </Box>

                    <Box display="flex" justifyContent="center" alignItems="center" my={3}>
                      <Accordion
                        sx={{
                          width: "100%",
                          background: "#F6F6F6",
                          boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.160784)",
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontWeight: 900 }} />} aria-controls="panel1a-content" id="panel1a-header">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DescriptionIcon sx={{ mr: 2 }} />
                            <Typography textAlign="center" sx={{ fontSize: "20px", fontWeight: "bold" }}>
                              Link
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            borderTop: "2px solid #6FDA44",
                            backgroundColor: "#FFFFFF",
                            p: 2,
                          }}
                        >
                          <Box>
                            {isConnected ? (
                              <>
                                {nft.walletId === wallet?.id ? (
                                  <Link                                    
                                    to={nft?.file_type?.toLowerCase() === "book" ? `/nft-detail/${nft?.id}` : `/video-library/${nft?.id}`}
                                    style={{ textDecoration: "none" }}
                                  >
                                    <ViewButton sx={{ width: "100%" }}>View Content</ViewButton>
                                  </Link>
                                ) : (
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    to={`/private-file/${nft?.id}`}
                                  >
                                    <ViewButton sx={{ width: "100%" }}>Access Private File</ViewButton>
                                  </Link>
                                )}
                              </>
                            ) : (
                              <>
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                  }}
                                  to={`/private-file/${nft?.id}`}
                                >
                                  <ViewButton sx={{ width: "100%" }}>Access Private File</ViewButton>
                                </Link>
                              </>
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" my={3}>
                      <Accordion
                        sx={{
                          width: "100%",
                          background: "#F6F6F6",
                          boxShadow: "0px 3px 3px rgba(0, 0, 0, 0.160784)",
                        }}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ fontWeight: 900 }} />} aria-controls="panel1a-content" id="panel1a-header">
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <DescriptionIcon sx={{ mr: 2 }} />
                            <Typography textAlign="center" sx={{ fontSize: "20px", fontWeight: "bold" }}>
                              Token
                            </Typography>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            borderTop: "2px solid #6FDA44",
                            backgroundColor: "#FFFFFF",
                            p: 2,
                          }}
                        >
                          <Typography
                            sx={{
                              fontweight: "bold",
                            }}
                          >
                            Token ID # {nft?.tokenId}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            </Box>
        </>
      </Box>
    </>
  );
};

export default NFTDetail;
