import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, Container, Typography } from "@mui/material";
import ViewContentImage1 from "../assets/domain-names-concept-illustration.jpg";
import ViewContentImage2 from "../assets/buffer-concept-illustration.jpg";
import ViewContentImage3 from "../assets/file-synchronization-concept-illustration.jpg";
import ViewContentImage from "../assets/youtube-tutorial-concept-illustration.jpg";
import { useNavigate } from "react-router-dom";

const ContentData = [
  {
    title: "Use browser in your crypto wallet",
    description:
      "Unlike watching conventional content, to watch Sniser content, you must do it via your crypto browser.  Coinbase and Metmask browsers are supported via Google Chrome. For mobile devices the browsers are found in the app",
    imageUrl: ViewContentImage1,
  },
  {
    title: "Automatic view function",
    description:
      "Once you have the NFT you do not need to do anything extra – it is automatically detected by the browser, and you are given access to view the content. It is as simple as that.",
    imageUrl: ViewContentImage2,
  },
  {
    title: "Watch content on a wide range of devices",
    description:
      "Content on Sniser follows the same systems and protocols as media from other sources. The only difference is how our content is accessed. Once you have ownership of our NFT via your desktop, laptop or mobile device you can stream it to another device like in car stereo system or television ",
    imageUrl: ViewContentImage3,
  },
];

function ViewContent() {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ backgroundColor: "#6FDA44" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column-reverse", md: "row" },
              paddingY: { xs: "39px", sm: "63px" },
            }}
          >
            <Box sx={{ maxWidth: "477px" }}>
              <Typography variant="h2" sx={{ mb: "20px", fontSize: "32px !important", color: "#2A3538", textTransform: "uppercase" }}>
                How to view your content
              </Typography>
              <Typography sx={{ mb: "31px", fontWeight: "400 !important" }}>
                Content created by our artists is stored on our servers as mp4 files. Therefore, viewing content is very simple when you have access to the
                internet. Media files will be shown in the same way that content is viewed from sources such as YouTube. You can even use Airplay to stream your
                content to a television or other screen.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Button
                  sx={{
                    background: "#2A3538",
                    fontWeight: 700,
                    color: "#ffffff",
                    textTransform: "capitalize",
                    padding: "10px 40px",
                  }}
                  onClick={() => navigate("/shop")}
                >
                  Buy Now
                </Button>
                <ArrowOutwardIcon sx={{ color: "#2A3538", fontSize: "24px" }} />
              </Box>
            </Box>
            <Box sx={{ width: { xs: "345px", md: "600px" }, height: { xs: "auto", md: "400px" }, mb: { xs: "23px", md: 0 } }}>
              <img src={ViewContentImage} alt="" style={{ objectFit: "cover" }} />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ paddingBottom: { xs: "63px", md: "99px" } }}>
        {ContentData?.map((item, index) => (
          <Box
            key={index.toString()}
            sx={{
              paddingY: { xs: "23px", md: "52px" },
              display: "flex",
              gap: "62px",
              flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
              backgroundColor: index % 2 === 0 ? "transparent" : "rgba(2, 3, 3, 0.05)",
            }}
          >
            <Container>
              <Box
                key={index.toString()}
                sx={{
                  paddingY: { xs: "23px", md: "40px" },
                  display: "flex",
                  gap: "62px",
                  flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
                }}
              >
                <Box sx={{ width: { xs: "345px", md: "450px" }, height: "auto", mb: { xs: "23px", md: 0 }, flexShrink: 0 }}>
                  <img src={item.imageUrl} alt="" style={{ objectFit: "cover" }} />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "64px", color: "#6FDA44" }}>{index + 1}.</Typography>
                  <Typography sx={{ fontSize: "32px", color: "#2A3538", textTransform: "uppercase" }}>{item.title}</Typography>
                  <Typography sx={{ fontWeight: "400 !important", color: "#2A3538" }}>{item.description}</Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        ))}
      </Box>
    </>
  );
}

export default ViewContent;
