import { Box, Grid, Skeleton, Typography } from "@mui/material";
import { isAxiosError } from "axios";
import { ethers } from "ethers";
import {    
  useContext,  
  useState,
} from "react";
import { useAccount } from "wagmi";
import { Buttonone as MyButton } from "../../components";
import { NFT_STATUS, CATEGORIES, LISTING, ORDER_STATUS } from "../../utils/constant";
import {
  approveNFT,
  putItemForSale,
  updateItemForSale,
  cancelItemForSale,  
  getNewOrderId,
  getApprovedNFT,
} from "../../utils/contract";
import { marketplaceContractAddr } from "../../connectivityAssets/mintContract/addresses";
import { useNfts } from "../../hooks";
import { post, get, update } from "../../utils/fetchApis";
import { config } from "../../utils/wagiConfig";
import { getEthersSigner } from "../../utils/ether";
import { AppContext } from "../../Context";
import SwitchPriceModal from "../../components/Modal/ListStatModal";
import NftListItem from "../../components/NftListItem";

const DashboardContent = () => {
  const [eNft, seteNft] = useState(null);
  const [status, setStatus] = useState(LISTING.START);
  const { isConnected } = useAccount();
  const [priceModalOpen, setPriceModalOpen] = useState(false);
  const { wallet, setAlertState } = useContext(AppContext)
  
  const {nfts, loading, execute} = useNfts();

  const toggelModal = () => {
    setPriceModalOpen(!priceModalOpen);
  };

  const completeList = async (price) => {
    try {            
      const signer = await getEthersSigner(config)
      setStatus(LISTING.APPROVING);

      const approved = await getApprovedNFT(eNft.tokenId)
      console.log('complete', approved)
      if (approved !== marketplaceContractAddr) {
        await approveNFT(signer, eNft.tokenId);
      }
      
      await putItemForSale(
        signer,
        eNft.tokenId,
        ethers.utils.parseUnits(price, 6),
      );

      const orderId = await getNewOrderId();

      const params = {
        marketplaceSaleId: parseInt(orderId) - 1,
        marketplaceAddress: marketplaceContractAddr,
        tokenId: eNft.tokenId,
        price,
        status: ORDER_STATUS.SALE,
        walletId: wallet.id,        
      };
      await post(`orders/${eNft.id}/create`, params);
      await execute();
      
      setStatus(LISTING.LIST_FINISH);
    } catch (e) {

      let message = 'something wrong happened'
      if (isAxiosError(e)) {
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const updateList = async (price) => {
    try {
      const signer = await getEthersSigner(config);      
      setStatus(LISTING.APPROVING);

      await updateItemForSale(
        signer,
        eNft.order.marketplaceSaleId,
        ethers.utils.parseUnits(price, 6),
      );
      const params = {        
        price,
      };
      await update(`orders/${eNft.order.id}`, params);
      await execute();

      setStatus(LISTING.EDIT_FINISH);
    } catch (e) {

        let message = 'Something wrong happened'
        if (isAxiosError(e)) {
          message = e.response.data.message;
        } else if (e.code) {
          message = e.reason ?? e.message;
        }      

        setAlertState({
          open: true,
          message,
          severity: 'error'
        })
      setPriceModalOpen(!priceModalOpen);
    }
  };

  const cancelList = async (nft) => {
    try {
      const signer = await getEthersSigner(config)      

      setStatus(LISTING.CANCELING);

      await cancelItemForSale(signer, eNft.order.marketplaceSaleId);
      const params = {        
        status: ORDER_STATUS.CANCEL,
      };
      await update(`orders/${eNft.order.id}`, params);
      await execute();

      setPriceModalOpen(false);
    } catch (e) {

      let message = 'Something wrong happened'
      if (isAxiosError(e)) {        
        message = e.response.data.message;
      } else if (e.code) {
        message = e.reason ?? e.message;
      }      

      setAlertState({
        open: true,
        message,
        severity: 'error'
      })
      setPriceModalOpen(!priceModalOpen);
    }
  };  

  const handleList = async (nft) => {    
    seteNft(nft);
    setStatus(nft.status === NFT_STATUS.SALE ? LISTING.EDIT : LISTING.START);
    setPriceModalOpen(true);
  };  

  return (
    <Box>
      {priceModalOpen && (
        <SwitchPriceModal
          status={status}
          nft={eNft}
          priceModalOpen={priceModalOpen}
          completeList={(price) => completeList(price)}
          updateList={(price) => updateList(price)}
          cancelList={cancelList}
          toggelModal={toggelModal}
        />
      )}
      
      <Box display={"flex"} flexDirection={"column"} rowGap={"15px"}>
        <Typography variant="h1" fontFamily="'Montserrat', sans-serif">
          Welcome to Sniser!
        </Typography>
        <Typography variant="h4" fontFamily="'Montserrat', sans-serif">
          The perfect place for selling and buying your personal NFTs!
        </Typography>
        <Typography color={"grey"} fontFamily="'Montserrat', sans-serif" fontWeight={"400 !important"}>
          Welcome to Sniser a NFT based marketplace where you can mint and sell your exclusive content. If you have not heard of a NFT let me, take some time to
          explain it to you:
        </Typography>
        <Typography fontWeight={"400 !important"} color={"grey"} fontFamily="'Montserrat', sans-serif">
          An NFT, or Non-Fungible Token, is like a special, digital certificate of ownership for something unique on the internet. When you mint an NFT on
          Sniser you are creating a digital token to allow people to view your content. Without having purchased one, people can’t view your content. What makes
          an NFT even better, is that you automatically receive commission whenever its resold.
        </Typography>
      </Box>
      <Box mt="20px" display={"flex"} alignItems="center" justifyContent={"space-between"}>
        <Typography variant="h1" fontFamily="'Montserrat', sans-serif">
          My Content
        </Typography>
        <MyButton> Show All </MyButton>
      </Box>
      <Grid container spacing={5} mt="10px" justifyContent={"center"}>        
        {nfts.map((nft, i) => (
          <Grid key={i} item md={4} xs={6} sm={6}>
            
              <NftListItem nft={nft} catagories={CATEGORIES} list={handleList} />
            
          </Grid>
        ))}
        {isConnected && nfts.length === 0 && (
          <Typography variant="h4" alignItems={"center"} mt={2} color="red">
            No Nfts
          </Typography>
        )}
        {!isConnected && (
          <Typography variant="h4" alignItems={"center"} mt={2} color="red">
            Connect Wallet To See Your Content
          </Typography>
        )}
      </Grid>
    </Box>
  );
};

export default DashboardContent;
