import { useState, useEffect, useCallback, useContext } from "react";
import { post, get } from "../utils/fetchApis";
import { DEV_MODE, NFT_ROYALTY_PERCENT, ORDER_STATUS } from "../utils/constant";
import { mintContractAddr } from "../connectivityAssets/mintContract/addresses";
import { AppContext } from "../Context";

function useNfts() {
  const [nfts, setNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const {wallet, setAlertState} = useContext(AppContext)

  const getNfts = useCallback(async (wallet) => {    
    
    if (!wallet) {
      setLoading(false)
      return  
    }
    
    let {success, data, message} = await get('nfts/user', {
      walletId: wallet?.id,
    })            

    if (!success) {
      setAlertState({
        open: true,
        message,
        severity: "error",
      })
      return
    }

    if (data.length) {
      const nfts = data.map((nft) => {
        const order = nft.orders && nft.orders.length ? nft.orders[0] : null;
        const buyOrders = nft.orders && nft.orders.filter((it) => it.status === ORDER_STATUS.SOLD);
        return {
          ...nft,
          ...order && {
            order,
            price: order.price,
            orderId: order?.marketplaceSaleId,  
          },          
          ...(buyOrders && buyOrders.length && { royalty: buyOrders.reduce((a, b) => Number(a) + Number(b.price) * NFT_ROYALTY_PERCENT, 0) }),
        };
      }).filter((n) => n.contractAddress === mintContractAddr);
      
      setNfts(nfts);      
    } else {
      setNfts(data)
    }
    setLoading(false);
  
  }, []);

  useEffect(() => {    
    getNfts(wallet);
  }, [wallet]);

  const refetch = () => {
    console.log('refetch nfts')
    getNfts(wallet)
  } 

  return {nfts, loading, execute: refetch};
}

export default useNfts;
