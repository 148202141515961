import "./styles.scss";

import PolicyBg from "../../assets/businesswoman-casual.png";
import useDocumentTitle from "../../useDocumentTitle";

const data = [
  {
    title: "Information We Collect",
    items: [
      "Personal Information: We may collect personal information that you provide to us voluntarily when you register an account, make a purchase, or communicate with us. This may include your name, email address, shipping address, billing information (including credit card details), and any other information you choose to provide.",
      "Usage Information: We may collect information about how you interact with our Website, including your IP address, browser type, device information, operating system, referral source, pages viewed, and the date and time of your visit. We may use cookies, web beacons, and similar technologies to collect this information.",
      "Blockchain Data: When you engage in transactions on our Website using cryptocurrency, we may collect and process blockchain data associated with your transactions, including transaction history, wallet addresses, and other relevant details.`",
    ],
  },
  {
    title: "Use of Information",
    items: [
      "Providing Services: We use the information we collect to provide, maintain, and improve our services, including facilitating the sale and purchase of NFT art, processing payments, and delivering purchased items to you.      ",
      "Communication: We may use your contact information to communicate with you about your account, transactions, updates, and promotions. You may opt out of receiving promotional communications at any time.      ",
      "Analytics and Personalization: We may analyze the information we collect to understand user preferences, improve our Website’s functionality, and personalize your experience, including displaying relevant content and recommendations.      ",
    ],
  },
  {
    title: "Disclosure of Information",
    items: [
      "Service Providers: We may share your personal information with trusted third-party service providers who assist us in operating our Website, processing payments, and delivering services to you. These service providers are contractually bound to protect your information and use it only for the purposes specified by us.      ",
      "Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity or parties involved as part of the transaction.      ",
      "Legal Obligations: We may disclose your information if we believe it is necessary to comply with applicable laws, regulations, legal processes, or enforceable governmental requests. We may also disclose your information to protect our rights, privacy, safety, or property or that of others.      ",
    ],
  },
  {
    title: "Data Security",
    items: [],
    content:
      "We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, please note that no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.    ",
  },
  {
    title: "Data Retention",
    items: [],
    content:
      "We retain your personal information for as long as necessary to fulfil the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.",
  },
  {
    title: "Third-Party Websites",
    items: [],
    content:
      "Our Website may contain links to third-party websites or services. This Privacy Policy does not apply to those websites or services, and we are not responsible for their privacy practices. We encourage you to review the privacy policies of those third parties before providing any personal information.      ",
  },
  {
    title: "Children’s Privacy",
    items: [],
    content:
      "Our Website may contain links to third-party websites or services. This Privacy Policy does not apply to those websites or services, and we are not responsible for their privacy practices. We encourage you to review the privacy policies of those third parties before providing any personal information.      ",
  },
  {
    title: "Changes to the Privacy Policy",
    items: [],
    content:
      "We may update this Privacy Policy occasionally to reflect changes in our practices or legal requirements. We will notify you of any material changes by posting the updated Privacy Policy on our Website or through other means. We encourage you to review the Privacy Policy periodically for any updates.      ",
  },
  {
    title: "Contact Us",
    items: [],
    content: `If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at <span  style="color:#6FDA44;font-weight:700;font-size:18px">support@sniser.com</span>`,
  },
];

const PrivacyPolicy = () => {
  useDocumentTitle("PRIVACY POLICY FOR NFT ART MARKETPLACE");

  return (
    <>
      <div className="banner-policy" style={{ backgroundImage: `url(${PolicyBg})` }}>
        <div className="bg-blur">
          <h2>PRIVACY POLICY FOR NFT ART MARKETPLACE</h2>
          <p>Effective Date: November 2023</p>
        </div>
      </div>
      <div className="content">
        <p className="content__heading">
          This Privacy Policy describes how we collect, use, disclose, and protect the personal information of users (“User” or “you”) of our website,
          www.sniser.io (“Website”), which operates as an online marketplace for the sale and purchase of NFT art in cryptocurrency. We value your privacy and
          are committed to protecting your personal information. By using our Website, you consent to the practices described in this Privacy Policy.
        </p>
        {data.map((item, parentIndex) => (
          <>
            <div className="content__title">
              <span className="content__title-index">{`${parentIndex + 1}. `}</span>
              <h4 className="content__title-text">{item.title}</h4>
            </div>
            {!!item.items.length ? (
              item.items.map((data, index) => (
                <div key={index.toString()} className="content__sub-title">
                  <span className="content__sub-title-index">{`${parentIndex + 1}.${index + 1}`}</span>
                  <h4 className="content__sub-title-text"> {data}</h4>
                </div>
              ))
            ) : (
              <p className="content__text" dangerouslySetInnerHTML={{ __html: item.content }} />
            )}
          </>
        ))}
        <p style={{ fontWeight: 500, fontSize: "22px", color: "#2a3539", marginBottom: "128px" }}>
          By using our Website, you acknowledge that you have read and understood this Privacy Policy and consent to the collection, use, and disclosure of your
          personal information as described herein.
        </p>
      </div>
    </>
  );
};

export default PrivacyPolicy;
