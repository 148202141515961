import { http, createConfig } from "wagmi";
import { polygon, polygonAmoy } from "wagmi/chains";
import { coinbaseWallet, metaMask, walletConnect, injected } from 'wagmi/connectors';
import { RPC } from "./constant";

export const config = createConfig({
    autoConnect: true,
    chains: [polygon, polygonAmoy],
    connectors: [
      metaMask(),
      coinbaseWallet(),      
      walletConnect({
        projectId: 'ada30691ace7bc5e72c9dfb30a030e06',
      }),
      injected(),            
    ],
    transports: {
      [polygon.id]: http(RPC.POLYGON),
      [polygonAmoy.id]: http(RPC.AMOY)
    },
  });