import Modal from "react-modal";
import { Link } from "react-router-dom";

const CookieNotifyModal = ({ open, setShowNotify }) => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-60%",
      transform: "translate(-50%, -50%)",
      maxWidth: "425px",
      backgroundColor: "#212529",
    },
  };
  
  return (
    <Modal isOpen={open} onRequestClose={setShowNotify} style={customStyles} shouldCloseOnOverlayClick={false}>
      <div className="modal-notify__body">
        <div style={{ fontSize: "16px", color: "#fff", textAlign: "center" }}>
          We use cookies to ensure that we give you the best experience on our website.
          <br /> If you continue to use this site we will assume that you are happy with it.{" "}
          <Link to="/cookie-policy" rel="noopener noreferrer" style={{ color: "#6FDA44" }}>
            More details
          </Link>
        </div>
        <button
          style={{
            marginLeft: "20px",
            borderRadius: "5px",
            height: "44px",
            width: "250px",
            color: "#fff",
            backgroundColor: "#6fda44",
            fontSize: "16px",
            fontWeight: 700,
            marginTop: "12px",
          }}
          onClick={() => {
            setShowNotify();
            localStorage.setItem("isAccept", "true");
          }}
        >
          Continue
        </button>
      </div>
    </Modal>
  );
};

export default CookieNotifyModal;
