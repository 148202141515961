import { useCallback, useEffect, useState, useContext } from "react";
import { AppContext } from '../Context';
import { NFT_STATUS } from "../utils/constant";
import { mintContractAddr } from "../connectivityAssets/mintContract/addresses";
import { get } from "../utils/fetchApis";

export default function useFetchNftList () {
  const [nfts, setNfts] = useState([]);  

  const getAllNfts = async () => {    
    
    let {success, data, message} = await get(`nfts`, {
      status: NFT_STATUS.SALE,
      contract_address: mintContractAddr,
    });
    
    return data;
  };

  const getNfts = useCallback(async () => {
    try {
      const data = await getAllNfts();
      
      setNfts(data)
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getNfts();
  }, [getNfts]);

  
  return {nfts};
};
