import { useState, useEffect, useCallback, useContext } from "react";
import { post, get } from "../utils/fetchApis";
import { DEV_MODE, NFT_ROYALTY_PERCENT, NFT_STATUS, ORDER_STATUS } from "../utils/constant";
import { mintContractAddr } from "../connectivityAssets/mintContract/addresses";

function useSaleNfts() {
  const [saleNfts, setSaleNfts] = useState([]);
  const [loading, setLoading] = useState(true);  

  const getSaleNfts = useCallback(async () => {            
    
    let {success, data, message} = await get('nfts', {
      status: NFT_STATUS.SALE,
      contract_address: mintContractAddr
    })            

    if (!success) {
      console.log('useSaleNfts error', message)    
      return
    }

    if (data.length) {
      const nfts = data.map((nft) => {        
        const order = nft.orders && nft.orders[0]
        const soldOrders = nft.orders && nft.orders.filter((it) => it.status === ORDER_STATUS.SOLD);
        return {
          ...nft,
          order: nft.orders[0],
          price: order.price,
          ...(soldOrders && soldOrders.length && { royalty: soldOrders.reduce((a, b) => Number(a) + Number(b.price) * NFT_ROYALTY_PERCENT, 0) }),
        };
      })

      // console.log(nfts)
      setSaleNfts(nfts);
      setLoading(false);
    }
  
  }, []);

  useEffect(() => {
    getSaleNfts();
  }, []);

  const refetch = () => {
    getSaleNfts()
  }

  return {saleNfts, loading, execute: refetch};
}

export default useSaleNfts;
