import { useState, useContext } from "react";
import { toast } from "react-toastify";
import "./styles.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation } from "react-router-dom";
import { post } from "../../utils/fetchApis";
import { useNavigate } from "react-router";
import { AppContext } from "../../Context";

const schema = yup.object().shape({
  password: yup.string().min(8).max(32).required(),
});

const ResetPassword = () => {
  const navigateFn = useNavigate();
  const { setAlertState } = useContext(AppContext);

  const [success, setSuccess] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const urlParams = new URLSearchParams(useLocation().search);
  
  const onSubmitHandler = async(form) => {
    
    const {success, data, message} = await post('auth/change_password', {
      code: urlParams.get('code'),
      email: urlParams.get('email'),
      password: form.password
    })

    if (!success) {
      setAlertState({
        open: true,
        message,
        severity: "error",
      })
    }
    navigateFn("/login")
    setSuccess(true);      
  };

  

  return (
    <>
      <br />
      <br />
      <div className="container">
        <div className="content-section" id="about-content">
          <div className="kid-header">
            <h3>Reset Password</h3>
          </div>

          <div id="wrapping" className="clearfix">
            <section id="aligned">
              {!!success && <div className="alert alert-danger text-center">Password Changed Sucessfully</div>}

              <form name="changepassfrm" onSubmit={handleSubmit(onSubmitHandler)}>
                <p style={{ marginBottom: "20px" }}>
                  <input
                    type="password"
                    id="password"
                    placeholder="Enter New password"
                    {...register("password")}
                    className={`form-control ${errors?.password && "invalid"}`}
                  />
                </p>

                <button id="button" className="btn theme-btn" type="submit" name="changepassfrm">
                  Change Password
                </button>
              </form>
            </section>
          </div>
        </div>
      </div>
      <br />
      <br />
    </>
  );
};

export default ResetPassword;
