import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from "@mui/material";

const ConfirmPaypalSubscriptionModal = ({open, confirm, cancel }) => {  
  
  return (
    <Dialog
        open={open}
        onClose={cancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Approve Payment
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please approve your subscription by visiting the link. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={cancel}>Cancel</Button>
          <Button onClick={confirm} autoFocus>
            Approve
          </Button>
        </DialogActions>
      </Dialog>
  );
};

export default ConfirmPaypalSubscriptionModal;
