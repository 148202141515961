import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { Box, Button, Container, Typography } from "@mui/material";
import WhyPurchaseImage1 from "../assets/why-purchase-1.png";
import WhyPurchaseImage2 from "../assets/why-purchase-2.png";
import WhyPurchaseImage3 from "../assets/why-purchase-3.png";
import WhyPurchaseImage from "../assets/why-purchase.png";
import { useNavigate } from "react-router-dom";

const ContentData = [
  {
    title: "VIEW EXCLUSIVE CONTENT",
    description:
      "When you purchase one of our NFT you are gaining a “key” to view exclusive content. You’ll be one of a select few to have seen what your artist has uploaded. It could be an album, song or video relating to something already in circulation. However, all content here is unique in its nature.",
    imageUrl: WhyPurchaseImage1,
  },
  {
    title: "Make a Profit",
    description:
      "All NFTs are limited edition content. Therefore, once all of the NFTs are owned, they gain value because the content cannot be viewed except by NFT owners. If you no longer want your NFT, you can put it back for sale (at a profit) for others to purchase. ",
    imageUrl: WhyPurchaseImage2,
  },
  {
    title: "Support up and coming artists",
    description:
      "All NFTs are limited edition content. Therefore, once all of the NFTs are owned, they gain value because the content cannot be viewed except by NFT owners. If you no longer want your NFT, you can put it back for sale (at a profit) for others to purchase. ",
    imageUrl: WhyPurchaseImage3,
  },
];

function WhyPurchase() {
  const navigate = useNavigate();

  return (
    <>
      <Box sx={{ backgroundColor: "#6FDA44" }}>
        <Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: { xs: "column-reverse", md: "row" },
              paddingY: { xs: "39px", sm: "63px" },
            }}
          >
            <Box sx={{ maxWidth: "477px" }}>
              <Typography variant="h2" sx={{ mb: "20px", fontSize: "32px !important", color: "#2A3538" }}>
                WHY PURCHASE AN NFT
              </Typography>
              <Typography sx={{ mb: "31px", fontWeight: "400 !important" }}>
                A NFT is a digital asset that is unique. It can be used for several purposes. Sniser uses NFT as a password to view protected information like
                videos and audio content. They can be viewed as either an item to collect or can be seen as an investment, if it is sold at a higher price than
                it was bought for.
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Button
                  sx={{
                    background: "#2A3538",
                    fontWeight: 700,
                    color: "#ffffff",
                    textTransform: "capitalize",
                    padding: "10px 40px",
                  }}
                  onClick={() => navigate("/shop")}
                >
                  Buy Now
                </Button>
                <ArrowOutwardIcon sx={{ color: "#2A3538", fontSize: "24px" }} />
              </Box>
            </Box>
            <Box sx={{ width: { xs: "345px", md: "600px" }, height: { xs: "auto", md: "400px" }, mb: { xs: "23px", md: 0 } }}>
              <img src={WhyPurchaseImage} alt="" style={{ objectFit: "cover" }} />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box sx={{ paddingBottom: { xs: "63px", md: "99px" } }}>
        {ContentData?.map((item, index) => (
          <Box
            key={index.toString()}
            sx={{
              paddingY: { xs: "23px", md: "52px" },
              display: "flex",
              gap: "62px",
              flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
              backgroundColor: index % 2 === 0 ? "transparent" : "rgba(2, 3, 3, 0.05)",
            }}
          >
            <Container>
              <Box
                key={index.toString()}
                sx={{
                  paddingY: { xs: "23px", md: "40px" },
                  display: "flex",
                  gap: "62px",
                  flexDirection: { xs: "column", md: index % 2 === 0 ? "row" : "row-reverse" },
                }}
              >
                <Box sx={{ width: { xs: "345px", md: "450px" }, height: "auto", mb: { xs: "23px", md: 0 }, flexShrink: 0 }}>
                  <img src={item.imageUrl} alt="" style={{ objectFit: "cover" }} />
                </Box>
                <Box>
                  <Typography sx={{ fontSize: "64px", color: "#6FDA44" }}>{index + 1}.</Typography>
                  <Typography sx={{ fontSize: "32px", color: "#2A3538", textTransform: "uppercase" }}>{item.title}</Typography>
                  <Typography sx={{ fontWeight: "400 !important", color: "#2A3538" }}>{item.description}</Typography>
                </Box>
              </Box>
            </Container>
          </Box>
        ))}
        <Container>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: { xs: "269px", sm: "272px" },
              backgroundColor: "#2A3538",
              borderRadius: "12px",
              textTransform: "uppercase",
              color: "#fff",
              fontSize: "32px",
              fontWeight: 700,
            }}
          >
            <Typography
              variant="h2"
              sx={{
                mb: { xs: "24px", sm: "30px" },
                fontSize: { xs: "24px !important", sm: "32px !important" },
                textAlign: "center",
                color: "#fff",
                textTransform: "uppercase",
              }}
            >
              Buy an NFT NOW!
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                mb: { xs: "24px", sm: "45px" },
                maxWidth: "680px",
                fontSize: { xs: "14px", sm: "20px" },
                color: "#fff",
                fontWeight: "400 !important",
                textTransform: "initial",
              }}
            >
              Purchase a NFT now to start viewing our range of exclusive content. A crypto wallet and Tether funds on Polygon network are required.
            </Typography>
            <Button
              sx={{
                background: "#6FDA44",
                fontWeight: 700,
                color: "#2A3538",
                textTransform: "capitalize",
                padding: "10px 40px",
                fontSize: { xs: "14px", sm: "16px" },
              }}
              onClick={() => navigate("/shop")}
            >
              View Shop
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default WhyPurchase;
