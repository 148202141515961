import "./styles.scss";

import AccountDeletionBg from "../../assets/creased-paper.png";
import Account1 from "../../assets/account1.png";
import Account2 from "../../assets/account2.png";
import useDocumentTitle from "../../useDocumentTitle";

const data = [
  {
    title: "Log into your account",
    content: `Click the <span style="font-weight:700">upload content</span> tab and the login pop-up will appear:`,
    imgSrc: Account1,
  },
  {
    title: "Click the link for your profile",
    content: "",
    imgSrc: Account2,
  },
  {
    title: `Click the <span style="color:#FF8282;font-size:28px;font-weight:700">DELETE ACCOUNT</span> link found at the bottom of the page.`,
    content: "",
  },
];

const AccountDeletion = () => {
  useDocumentTitle("Sniser Account Deletion");

  return (
    <>
      <div className="banner-account-detection" style={{ backgroundImage: `url(${AccountDeletionBg})` }}>
        <div className="bg-blur">
          <h2>Sniser Account Deletion</h2>
        </div>
      </div>
      <div className="content">
        <p className="content__heading" style={{ fontWeight: 700, fontSize: "22px" }}>
          If you delete your account. You can't regain access once it's deleted. You will have to create a new account to use our services. We delay the
          deletion process for a few days after it's requested, so you only have a few days to change your mind before the deletion is complete and final.
        </p>
        <p className="content__heading">
          The <span style={{ fontWeight: 700 }}>NFTs and content will still be available to users</span> who have purchased the content. This means that
          customers will still have access to the content that you created before your account has been deleted.
          <br /> If you used an app like Facebook or Google to login to your account, it will be disassociated from your account when the deletion process is
          complete.
        </p>
        <h3 style={{ fontSize: "36px", fontWeight: 700, color: "#2a3539", marginBottom: "60px" }}>How to delete your account ?</h3>
        {data.map((item, parentIndex) => (
          <>
            <div className="content__title">
              <span className="content__title-index">{`${parentIndex + 1}. `}</span>
              <h4 className="content__title-text" dangerouslySetInnerHTML={{ __html: item.title }} />
            </div>
            {item.content && <p className="content__text" dangerouslySetInnerHTML={{ __html: item.content }} />}
            {item.imgSrc && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={item.imgSrc} alt="" style={{ marginBottom: "48px" }} />
              </div>
            )}
          </>
        ))}
      </div>
    </>
  );
};

export default AccountDeletion;
