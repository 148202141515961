import { Backdrop, Box } from "@mui/material";
import { BubbleSpinLoader } from "react-css-loaders2";

const Loading = ({ isLoading }) => {
  return (
    
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000000 }}
      open={isLoading}
    >
      <BubbleSpinLoader color="#6FDA44" />
    </Backdrop>    
  );
};

export default Loading;
